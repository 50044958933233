import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const HalfWidthImageWrapper = styled("div")<{ position: string }>`
  display: flex;
  justify-content: ${(props: { position: string }) => props.position};
  padding: 0 10.3% 96px;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 80px;
    justify-content: flex-end;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 48px;
  `}
`;

export const Image = styled("div")<{ width: string }>`
  width: ${(props: { width: string }) => (props.width ? props.width : "59%")};
  ${mediaMax.tabletLandscape`
    width: 100%;
  `};
  ${mediaMax.phoneXL`
    width: 65.5%;
  `};
`;
