import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { TheMastersWrapper, ColLeft, ColRight } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const TheMasters: FC = () => {
  const theMastersWrapperRef = useRef<HTMLDivElement>(null);
  const colLeftRef = useRef<HTMLDivElement>(null);
  const colRightRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let item;
  item = data.strapiTheNeighborhoodPage.CultureSection.TheMasters;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: theMastersWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          colRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          colLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  return (
    <TheMastersWrapper ref={theMastersWrapperRef}>
      <ColLeft ref={colLeftRef}>
        <GatsbyImage
          className={"mb-48"}
          image={
            item.HalfWidthImageLeft.localFile.childImageSharp.gatsbyImageData
          }
          alt={"200East83rd"}
        />
        <h2 className={"item-title-s"}>{item.TitleLeft}</h2>
        <p className={"article-copy-16 mb-24"}>{item.DescriptionLeft}</p>
        <h3 className={"item-name"}>{item.NameLeft}</h3>
        <p className={"item-address"}>{item.AddressLeft}</p>
        <h3 className={"item-info-title"}>{item.InfoTitleLeft}</h3>
        <p className={"item-info-description"}>{item.InfoDescriptionLeft}</p>
      </ColLeft>
      <ColRight ref={colRightRef}>
        <h2 className={"item-title"}>{item.TitleRight}</h2>
        <p className={"article-copy-16 mb-24"}>{item.DescriptionRight}</p>
        <h3 className={"item-name"}>{item.NameRight}</h3>
        <p className={"item-address"}>{item.AddressRight}</p>
        <h3 className={"item-info-title"}>{item.InfoTitleRight}</h3>
        <p className={"article-copy-16 mb-24"}>{item.InfoDescriptionRight}</p>
        <h3 className={"item-info-title"}>{item.infoTitleRight2}</h3>
        <p className={"item-info-description mb-40"}>
          {item.InfoDescriptionRight2}
        </p>
        <GatsbyImage
          image={
            item.HalfWidthImageRight.localFile.childImageSharp.gatsbyImageData
          }
          alt={"200East83rd"}
        />
      </ColRight>
    </TheMastersWrapper>
  );
};

export default TheMasters;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        TheMasters {
          AddressLeft
          AddressRight
          DescriptionLeft
          DescriptionRight
          InfoDescriptionLeft
          InfoDescriptionRight
          InfoDescriptionRight2
          InfoTitleLeft
          InfoTitleRight
          NameLeft
          NameRight
          TitleLeft
          TitleRight
          infoTitleRight2
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
