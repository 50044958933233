import React, { FC } from "react";
import { ParksWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import ImageImage from "../image-image";
import Item1Text from "../item1-text";
import ItemImageText from "../item-image-text";

const Parks: FC = () => {
  return (
    <ParksWrapper>
      <Item>
        <FullWidthImage img={"Parks1"} />
        <ImageImage img={"Parks1-2"} />
        <Item1Text name={"ConservatoryGarden"} />
      </Item>
      <Item>
        <FullWidthImage img={"Parks2"} />
        <ItemImageText
          name={"twilight"}
          reversed={true}
          imgWidth={"61.6%"}
          textWidth={"34%"}
        />
      </Item>
      <Item>
        <FullWidthImage img={"Parks3"} />
      </Item>
      <Item>
        <ItemImageText
          name={"zoo"}
          reversed={true}
          imgWidth={"28.5%"}
          textWidth={"67.1%"}
          smallTitle={true}
        />
      </Item>
      <Item>
        <FullWidthImage img={"Parks4"} />
        <FullWidthImage img={"Parks5"} />
        <ItemImageText
          name={"winter"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Item1Text name={"Pursuits"} smallTitle={true} />
      </Item>
      <FullWidthImage img={"Parks6"} />
    </ParksWrapper>
  );
};

export default Parks;
