import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "../../helpers/MediaQueries";

export const NextPageSectionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 10.3% 180px;
  position: relative;
  ${mediaMax.tabletLandscape`
    padding: 48px 10.3% 100px;
  `};
`;

// export const NextPageImage = styled.div`
//   width: 36%;
//   margin: 0 1.5% 0 auto;
//   ${mediaMax.tabletLandscape`
//     padding-bottom: 40px;
//     width: 70%;
//     margin: 0 auto;
//   `};
// `;

export const NextPageButton = styled(Link)`
  width: 48.5%;
  text-decoration: none;
  ${mediaMax.tabletLandscape`
    text-align: center;
    width: 90%;
    margin: 0 auto;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
  `};
  .body-title {
    color: #1a1818;
    opacity: 0.88;
    padding-bottom: 8px;
    white-space: pre-line;
    ${mediaMax.tabletLandscape`
      padding-bottom: 28px;
      font: normal normal normal 40px/44px RR CUSTOM;
    `};
    ${mediaMax.phoneXL`
      padding-bottom: 12px;
      font: normal normal normal 35px/38px RR CUSTOM;
    `};
  }
  .button-text {
    color: #000;
    opacity: 1;
    ${mediaMax.phoneXL`
      padding-bottom: 12px;
      font: normal normal normal 12px/20px Founders Grotesk;
    `};
  }
`;

export const IntroHorizontalLine = styled.div`
  display: none;
  ${mediaMax.tabletLandscape`
    display: block;  
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 0;
    background-color: #000;
  `}
`;
