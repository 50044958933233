import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const NavBarWrapper = styled.div`
  width: 100%;
  padding: 0 10.3% 48px;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 40px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 32px;
  `};
`;

export const NavItems = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #1a1818;
  border-top: 1px solid #1a1818;
  ${mediaMax.tabletLandscape`
    width: 352px;
    margin: 0 auto;
  `};
  ${mediaMax.tablet`
    width: 300px;
    margin: 0 auto;
  `};
  ${mediaMax.phoneXL`
    width: 208px;
    margin: 0 auto;
  `};

  a {
    display: inline-block;
    margin: 48px 31px;
    text-align: center;
    font: normal normal normal 12px/12px Founders Grotesk;
    letter-spacing: 0.6px;
    color: #1a1818;
    text-transform: uppercase;
    opacity: 0.3;
    transition: 0.4s ease-out;
    ${mediaMax.tabletLandscape`
      margin: 24px 27px;
    `};
    ${mediaMax.tablet`
      margin: 24px 16px;
    `};
    ${mediaMax.phoneXL`
      font: normal normal normal 10px/10px Founders Grotesk;
      margin: 24px 8px;
    `};
    ${mediaMax.phoneS`
      font: normal normal normal 9px/9px Founders Grotesk;
      margin: 24px 5px;
    `};
    &:hover,
    &.active {
      opacity: 1;
    }
  }
`;
