import * as React from "react";
import IntroSection from "../components/intro-section";
import FullWidthImage from "../components/full-width-image";
import TextField from "../components/text-field";
import HalfWidthImage from "../components/half-width-image";
import NextPageSection from "../components/next-page-section";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Penthouses = () => {
  return (
    <>
      <Seo page={SeoData.penthouses}/>
      <PageLabel name={"Chapter THREE"} description={"The PENTHOUSES"} />
      <IntroSection page={"thePenthousesPage"} />
      <FullWidthImage img={"PenthousesImg1"} withPadding={false} />
      <TextField page={"Penthouses1"} />
      <FullWidthImage img={"PenthousesImg2"} withPadding={false} />
      <FullWidthImage img={"PenthousesImg3"} withPadding={false} />
      <FullWidthImage img={"PenthousesImg4"} withPadding={false} />
      <HalfWidthImage
        img={"PenthousesImg1"}
        width={"59%"}
        position={"center"}
      />
      <FullWidthImage img={"PenthousesImg5"} withPadding={false} />
      <NextPageSection page={"thePenthousesPage"} />
      <Footer />
    </>
  );
};

export default Penthouses;
