import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ImageImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ImageLeft = styled.div`
  width: 47.8%;
`;

export const ImageRight = styled.div`
  width: 47.8%;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `};
`;
