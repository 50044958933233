import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const ImageTextWrapper = styled("div")<{ reversed: boolean }>`
  padding: 0 10.3% 96px;

  display: flex;
  justify-content: space-between;
  flex-direction: ${(props: { reversed: boolean }) =>
    props.reversed ? "row-reverse" : "row"};
  ${mediaMax.tabletLandscape`
    flex-direction: column;
    padding: 0 15.2%;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7%;
  `};
`;

export const Image = styled("div")<{ imgWidth: string }>`
  width: ${(props: { imgWidth: string }) => props.imgWidth};
  ${mediaMax.tabletLandscape`
    width: 100%;
    padding-bottom: 48px;
  `};
`;

export const Text = styled("div")<{ textWidth: string }>`
  width: ${(props: { textWidth: string }) => props.textWidth};
  white-space: pre-line;
  ${mediaMax.tabletLandscape`
    width: 100%;
    padding-bottom: 48px;
  `};
`;
