import * as React from "react";

import IntroSection from "../components/intro-section";
import FullWidthImage from "../components/full-width-image";
import ArchitectSection from "../components/building/architect-section";
import RamsaSection from "../components/building/ramsa-section";
import FacadeSection from "../components/building/facade-section";
import NextPageSection from "../components/next-page-section";
import Footer from "../components/footer";
import TextField from "../components/text-field";
import PageLabel from "../components/page-label";
import VideoIntro from "../components/building/video-intro";
import Seo from "../components/seo";
import SeoData from "../data/seo"

const IndexPage = () => {
  return (
    <>
      <Seo page={SeoData.building}/>
      <VideoIntro />
      <PageLabel name={"Chapter One"} description={"The Building"} />
      <IntroSection page={"theBuildingPage"} />
      <FullWidthImage img={"BuildingImg1"} withPadding={false} />
      <FullWidthImage img={"BuildingImg2"} withPadding={true} />
      <ArchitectSection />
      <RamsaSection />
      <TextField page={"Building1"} />
      <FacadeSection />
      <TextField page={"Building2"} />
      <FullWidthImage img={"BuildingImg3"} withPadding={true} />
      <FullWidthImage img={"BuildingImg4"} withPadding={false} />
      <NextPageSection page={"theBuildingPage"} />
      <Footer />
    </>
  );
};

export default IndexPage;
