import * as React from "react";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import AvailabilityItems from "../components/availability/availability-items";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Availability = () => {
  return (
    <>
      <Seo page={SeoData.availability} />
      <PageLabel name={"Availability"} />
      <AvailabilityItems />
      <Footer />
    </>
  );
};

export default Availability;
