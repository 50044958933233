import React, { FC } from "react";
import { LifestyleWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import Item1Text from "../item1-text";
import ItemImageText from "../item-image-text";
import Primp from "../primp";

const Lifestyle: FC = () => {
  return (
    <LifestyleWrapper>
      <Item>
        <ItemImageText
          name={"polish"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Primp />
      </Item>
      <Item>
        <FullWidthImage img={"Lifestyle1"} />
        <Item1Text name={"Workout"} />
        <FullWidthImage img={"Lifestyle2"} />
      </Item>
    </LifestyleWrapper>
  );
};

export default Lifestyle;
