import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  ArchitectArticleWrapper,
  ArticleImages,
  ArticleQuotation1,
  Article2ColText,
  ArticleQuotation2,
} from "./index.styled";

const ArchitectArticle: FC = () => {
  const data = useStaticQuery(query);
  const { ExpandedArchitectArticle: items } = data.strapiTheBuildingPage;
  return (
    <ArchitectArticleWrapper id={"article"}>
      <Article2ColText className={"article-copy-16"}>
        <p>{items.IntroText}</p>
        <p>{items.IntroTextRight}</p>
      </Article2ColText>

      <ArticleImages>
        {items.ArticleRowImages.map((el: any, i: number) => (
          <span key={i}>
            <GatsbyImage
              image={el.Image[0].localFile.childImageSharp.gatsbyImageData}
              alt={"200East83rd"}
            />
            <p>{el.ImageLabel}</p>
          </span>
        ))}
      </ArticleImages>

      <ArticleQuotation1>
        <p className={"body-copy"}>{items.ArticleQuotation1.QuotationText}</p>
        <p className={"quotation-author-18"}>
          {items.ArticleQuotation1.QuotationAuthor}
        </p>
      </ArticleQuotation1>

      <Article2ColText className={"article-copy-16"}>
        <p>{items.ArticleTextLeft}</p>
        <p>{items.ArticleTextRight}</p>
      </Article2ColText>

      <ArticleQuotation2>
        <p className={"body-title"}>{items.ArticleQuotation2.QuotationText}</p>
        <p className={"quotation-author-18"}>
          {items.ArticleQuotation2.QuotationAuthor}
        </p>
      </ArticleQuotation2>
    </ArchitectArticleWrapper>
  );
};

export default ArchitectArticle;

const query = graphql`
  {
    strapiTheBuildingPage {
      id
      ExpandedArchitectArticle {
        ArticleTextLeft
        ArticleTextRight
        IntroText
        IntroTextRight
        id
        ArticleQuotation1 {
          QuotationAuthor
          QuotationText
        }
        ArticleQuotation2 {
          QuotationAuthor
          QuotationText
        }
        ArticleRowImages {
          ImageLabel
          Image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
