import styled from "styled-components";
import { mediaMax } from "../../../../helpers/MediaQueries";

export const ArchitectArticleWrapper = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 3s ease-out;
  &.active {
    max-height: 100%;
    opacity: 1;
  }
  ${mediaMax.tabletLandscape`
    padding: 0 5.8%;
  `}
  ${mediaMax.phoneXL`
    padding: 0 6.5%;
  `}
`;

export const ArticleImages = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 96px;
  ${mediaMax.tabletLandscape`
    flex-wrap: wrap;
    padding: 0 9% 40px;
  `}
  ${mediaMax.phoneXL`
    padding: 0 0 32px;
  `}
  span {
    display: inline-block;
    width: 25%;
    padding-right: 12px;
    ${mediaMax.tabletLandscape`
      width: 50%;
      padding: 0 6px;
    `}
    ${mediaMax.phoneXL`
      padding: 0;
      width: 47%;
    `}
    p {
      padding-top: 12px;
      text-align: left;
      font: normal normal normal 12px/20px Founders Grotesk;
      letter-spacing: 0.96px;
      color: #1a1818;
      text-transform: uppercase;
      ${mediaMax.tabletLandscape`
        padding: 8px 0 16px;
        font: normal normal normal 12px/20px Founders Grotesk;
      `}
    }
  }
`;
export const ArticleQuotation1 = styled.div`
  max-width: 46%;
  padding-bottom: 44px;
  ${mediaMax.tabletLandscape`
    max-width: 100%;
    padding: 0 9.8% 36px;
  `}
  ${mediaMax.phoneXL`
    padding: 0 0 36px;
  `}
  .quotation-author-18 {
    ${mediaMax.tabletLandscape`
      font: normal normal 700 18px/20px Founders Grotesk;
    `}
    ${mediaMax.phoneXL`
      font: normal normal 700 12px/16px Founders Grotesk;
    `}
  }

  p {
    padding-bottom: 12px;
  }
`;
export const Article2ColText = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: pre-line;
  padding-bottom: 96px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 48px;
  `}
  ${mediaMax.phoneXL`
    flex-wrap: wrap;
    padding-bottom: 40px;
  `}
  p {
    width: 48.5%;
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }
`;
export const ArticleQuotation2 = styled.div`
  text-align: center;
  padding: 0 8.15% 84px;
  ${mediaMax.tabletLandscape`
    padding: 0 8.15% 44px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 8.15% 36px;
  `};
  p {
    padding-bottom: 12px;
  }
  .body-title {
    ${mediaMax.tabletLandscape`
      font: normal normal normal 24px/34px RR CUSTOM;
      letter-spacing: 1px;
    `};
  }
  .quotation-author-18 {
    ${mediaMax.tabletLandscape`
      font: normal normal 700 12px/20px Founders Grotesk;
      letter-spacing: 0.96px;
    `};
  }
`;
