import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import {
  ArticleNav,
  ArticleWrapper,
  Type,
  ArticleContent,
  Logo,
  MainImage,
} from "./index.styled";
import useWindowSize from "../helpers/UseWindowSize";
import { GatsbyImage } from "gatsby-plugin-image";
import Footer from "../components/footer";

const Article = ({ data }) => {
  const size = useWindowSize();
  const item = data.strapiArticles;
  const max = data.allStrapiArticles.edges.length - 1;
  let curr = data.strapiArticles.strapiId;
  const [iter, setIter] = useState(curr);

  useEffect(() => {
    if (curr <= max) {
      setIter(curr + 1);
    } else {
      setIter(1);
    }
  }, [curr, max]);

  return (
    <ArticleWrapper key={item.strapiId}>
      <ArticleNav>
        <Link className={"back"} to={"/press"}>
          Back to press
        </Link>
        <p>{item.ArticleDate}</p>
        <Link className={"next"} to={`/Articles_${iter}`}>
          Next article
        </Link>
      </ArticleNav>
      <Type className={"item-info-title"}>PRESS</Type>
      <ArticleContent>
        <Logo>
          <h2>{item.ArticleLogo}</h2>
        </Logo>
        <h1 className={"item-title-s"}>{item.ArticleTitle}</h1>
        <MainImage>
          <GatsbyImage
            image={
              item.ArticleMainImage.localFile.childImageSharp.gatsbyImageData
            }
            alt={"200East83rd"}
          />
        </MainImage>
        {item.ArticleSubtitle && <h3>{item.ArticleSubtitle}</h3>}
        {item.ArticleText && <p>{item.ArticleText}</p>}
      </ArticleContent>
      <ArticleNav className={"bottomNav"}>
        {size.width > 1025 && (
          <Link className={"back"} to={"/press"}>
            Back to press
          </Link>
        )}
        <a
          className={"source"}
          target={"_blank"}
          rel="noreferrer"
          href={item.ArticlePDF?.localFile?.url? item.ArticlePDF.localFile?.url : item.ArticleSource}
        >
          View article source
        </a>
        {size.width > 1025 && <Link to={`/Articles_${iter}`} />}
      </ArticleNav>
      <Footer />
    </ArticleWrapper>
  );
};

export default Article;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticles(id: { eq: $id }) {
      ArticleDate(formatString: "MMMM DD, YYYY", locale: "en")
      ArticleDescription
      ArticleSubtitle
      ArticleText
      ArticleTitle
      ReadMoreButton
      ArticleSource
      ArticlePDF {
          localFile {
            url
          }
        }
      strapiId
      slug
      id
      ArticleLogo 
      ArticleMainImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allStrapiArticles {
      edges {
        node {
          id
        }
      }
    }
  }
`;
