import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const FullWidthImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
  ${mediaMax.phoneXL`
      padding-bottom: 40px;
  `};
`;

export const Image = styled.div`
  width: 100%;
`;
