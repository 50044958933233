import React from "react";
import { Helmet } from "react-helmet";
import {ISeoProps} from "../../interfaces/seo-props";


const SEO = ({...props}) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "200 East 83rd",
    "image": "https://200east83rd.com/static/Hero_Overall_Day-0a60e3799f5fb30983d13a3c9658d16f.png",
    "url": "https://200east83rd.com/",
    "telephone": "516 788 7908",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "200 East 83rd Street",
      "addressLocality": "New York",
      "addressRegion": "NY",
      "postalCode": "10028",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 40.7766789,
      "longitude": -73.9552566
    }
  }
  return (
    <Helmet title={props.page.title} titleTemplate={"%s"}   htmlAttributes={{lang: 'en-us'}}>
      <meta name="description" content={props.page.description} />
      <meta name="google-site-verification" content="zjBgxzDLVB49xGHQcxPkE2euOR4JFJE57zIhBaYQhcM" />
      <meta name="msvalidate.01" content="D4AFB01BDE9052C5DDBAB19A44A89E98" />
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
    </Helmet>
  );
}

export default SEO
