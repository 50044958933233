import React, { FC } from "react";
import { MarketsWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import ImageImage from "../image-image";
import Item1Text from "../item1-text";
import ItemImageText from "../item-image-text";

const Markets: FC = () => {
  return (
    <MarketsWrapper>
      <Item>
        <ItemImageText
          name={"bouquets"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <ItemImageText
          name={"bakeries"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Item1Text name={"Fish"} smallTitle={true} />
      </Item>
      <Item>
        <Item1Text name={"Grocer"} />
        <FullWidthImage img={"Markets1"} />
      </Item>
      <Item>
        <ItemImageText
          name={"roasts"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <FullWidthImage img={"Markets2"} />
        <Item1Text name={"IceCream"} />
      </Item>
      <ImageImage img={"Markets1-2"} />
    </MarketsWrapper>
  );
};

export default Markets;
