import React, {FC, useState, Dispatch, SetStateAction, useEffect} from "react";
import Header from "../header";
import { GlobalStyle } from "./index.styled";
import { ILayoutProps } from "../../interfaces/layout-props";
import { LayoutWrapper, PageWrapper } from "./index.styled";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from 'gsap/CSSPlugin';


gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSPlugin);
gsap.config({
  nullTargetWarn: false,
});

type ContextProps = {
  isMenuActive: boolean;
  setIsMenuActive: Dispatch<SetStateAction<boolean>>;
};
export const HeaderContext = React.createContext<ContextProps>({
  isMenuActive: false,
  setIsMenuActive(value: ((prevState: boolean) => boolean) | boolean): void {
    /**/
  },
});

const Layout: FC<ILayoutProps> = ({ children, location }) => {
  useEffect(() => {
    history.replaceState(
      "",
      document.title,
      window.location.origin + window.location.pathname + window.location.search
    );
  });
  const [isMenuActive, setIsMenuActive] = useState(false);
  return (
    <HeaderContext.Provider
      value={{
        isMenuActive,
        setIsMenuActive,
      }}
    >
      <PageWrapper>
        <GlobalStyle />
        <Header location={location} />
        <LayoutWrapper id="layoutWrapper">
          <div>{children}</div>
        </LayoutWrapper>
      </PageWrapper>
    </HeaderContext.Provider>
  );
};
export default Layout;
