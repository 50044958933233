import React, { FC, useEffect, useRef, useContext } from "react";
import { HeaderContext } from "../layout";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import gsap from "gsap";
import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoItem,
  CloseBtn,
  HeaderNavigationBlock1,
  HeaderNavigationBlock2,
  HeaderNavigationBlock3,
  HeaderNavigation,
  MenuBtn,
  HeaderNavigationLink,
} from "./index.styled";
import logo from "../../images/logo-main.svg";
import insta from "../../images/icon-IG.svg";
import close from "../../images/icon-close-light.svg";
import sop from '../../files/NY-StandardOperatingProcedures.pdf'

import { IHeaderProps } from "../../interfaces/header-props";

const pagesData1 = [
  {
    desc: "Introduction",
    link: "/#videoPlayer",
  },
  {
    name: "Chapter One",
    desc: "The Building",
    link: "/#theBuildingPage",
  },
  {
    name: "Chapter Two",
    desc: "The Residences",
    link: "/residences#theResidencesPage",
  },
  {
    name: "Chapter Three",
    desc: "The Penthouses",
    link: "/penthouses#thePenthousesPage",
  },
  {
    name: "Chapter Four",
    desc: "The Amenities",
    link: "/amenities#theAmenitiesPage",
  },
  {
    name: "Chapter Five",
    desc: "The Neighborhood",
    link: "/neighborhood#theNeighborhoodPage",
  },
];
const pagesData2 = [
  {
    name: "Team",
    link: "/team",
  },
  {
    name: "Availability",
    link: "/availability",
  },
  {
    name: "Press",
    link: "/press",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const Header: FC<IHeaderProps> = ({ location }) => {
  const headerNavigationRef = useRef<HTMLDivElement>(null);
  const { isMenuActive, setIsMenuActive } = useContext(HeaderContext);
  const toggleActive = () => {
    setIsMenuActive(!isMenuActive);
  };
  useEffect(() => {
    const actionNav = gsap.fromTo(
      ".animate",
      { y: "0", duration: 0.75, ease: "ease", paused: true },
      { y: "-=131", duration: 0.75, ease: "ease", paused: true }
    );
    ScrollTrigger.create({
      trigger: "#layoutWrapper",
      start: `+=200top top`,
      end: `bottom bottom`,
      id: "headerAnimate",
      onUpdate: ({ direction, isActive }) => {
        const htmlVideoPlayer: any = document
          .querySelector("#videoPlayer")
          ?.getBoundingClientRect().bottom;
        (htmlVideoPlayer <= -100 || htmlVideoPlayer === undefined) &&
        direction === 1 &&
        isActive
          ? actionNav.play()
          : actionNav.reverse();
      },
    });
  }, []);

  return (
    <>
      <HeaderContainer id="headerWrapper">
        <HeaderLogo to={"/"} className={"animate"}>
          <HeaderLogoItem src={logo} alt={"200East83rd"}/>
        </HeaderLogo>
        <HeaderNavigation
          ref={headerNavigationRef}
          id={"headerNavigation"}
          className={isMenuActive ? "active" : ""}
        >
          <CloseBtn src={close} alt={"close icon"} onClick={toggleActive} />

          <HeaderNavigationBlock1>
            {pagesData1.map((el, key) => (
              <HeaderNavigationLink
                onClick={toggleActive}
                to={el.link}
                key={key}
                className={
                  location.pathname === el.link ||
                  location.pathname === el.link + "/"
                    ? "active"
                    : ""
                }
              >
                {el.name && el.name}
                {el.desc && <p>{el.desc}</p>}
              </HeaderNavigationLink>
            ))}
          </HeaderNavigationBlock1>
          <HeaderNavigationBlock2>
            {pagesData2.map((el, key) => (
              <HeaderNavigationLink
                onClick={toggleActive}
                to={el.link}
                key={key}
                className={
                  location.pathname === el.link ||
                  location.pathname === el.link + "/"
                    ? "active"
                    : ""
                }
              >
                {el.name && el.name}
              </HeaderNavigationLink>
            ))}
          </HeaderNavigationBlock2>
          <HeaderNavigationBlock3>
            <a
              href="https://www.instagram.com/200east83rd/"
              target="_blank"
              className={"insta-icon"}
            >
              <img src={insta} alt="Instagram" />
            </a>
            <HeaderNavigationLink
              onClick={toggleActive}
              to={"/legal"}
              className={
                location.pathname === "legal" || location.pathname === "legal/"
                  ? "active"
                  : ""
              }
            >
              Legal
            </HeaderNavigationLink>
            <a
              href="/fairhousingnotice.pdf"
              target="_blank"
              className={"menu-link"}
            >
              Fair Housing Notice
            </a>
            <a
              href={sop}
              target="_blank"
              className={"menu-link"}
            >
              Standard Operating Procedures
            </a>
          </HeaderNavigationBlock3>
        </HeaderNavigation>
        <MenuBtn onClick={toggleActive} className={"animate"}>
          <p>MENU</p>
          <span />
        </MenuBtn>
      </HeaderContainer>
    </>
  );
};

export default Header;
