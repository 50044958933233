import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const Item1Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 24px;
  `};
`;

export const ColLeft = styled.div`
  width: 47.8%;
`;

export const ColRight = styled.div`
  width: 47.8%;
  ${mediaMax.tabletLandscape`
      width: 100%;
  `};
`;
