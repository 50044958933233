import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ContactBodyWrapper = styled.div`
  background: #fbf7f2;
  transition: opacity 0.3s linear;
`;
export const ContactBodyCloseIcon = styled.div`
  position: absolute;
  right: 38px;
  top: 45px;
  width: 20px;
  height: 20px;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s;
  will-change: opacity;
  z-index: 10;

  ${mediaMax.phoneXL`
    position: fixed;
    right: 24px;
    top: 32px;
  `}
  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    content: " ";
    height: 24px;
    width: 1px;
    background-color: #1a1818;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;
export const ContactBodyLogoImage = styled.img`
  position: absolute;
  left: 28px;
  top: 32px;
  width: 80px;
  cursor: pointer;
`;
export const ContactThanks = styled.div`
  text-align: center;
  font: normal normal normal 40px/40px RR CUSTOM;
  letter-spacing: 2.4px;
  color: #1a1818;
  text-transform: uppercase;
  margin-bottom: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 124px);
  max-width: 440px;
  ${mediaMax.tabletLandscape`
    margin: 0 auto;
  `}
  ${mediaMax.phoneXL`
    font-size: 32px;
    line-height: 32px;
  `}
`;
export const ContactHeaderText = styled.h3`
  text-align: center;
  font: normal normal normal 14px/14px RR CUSTOM;
  letter-spacing: 1.12px;
  color: #000000;
  text-transform: uppercase;
  margin: 0 0 96px;
`;
export const ContactText = styled.p`
  text-align: center;
  font: normal normal normal 12px/36px Founders Grotesk;
  letter-spacing: 0.6px;
  color: #1a1818;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: 96px;

  ${mediaMax.phoneXL`
    margin-bottom: 40px;
  `}
`;
export const ContactFormContainer = styled.div`
  padding: 0;
  margin: -92px auto 0;
  min-height: calc(100vh - 172px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${mediaMax.phoneXL`
    padding: 0px 0 56px;
  `}
`;
