import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { NavBarWrapper, NavItems } from "./index.styled";
import { INavBar } from "../../../interfaces/nav-bar-props";

const NavBar: FC<INavBar> = ({ category }) => {
  const data = useStaticQuery(query);
  const { Link: items } = data.strapiTheNeighborhoodPage.NavBar;

  return (
    <NavBarWrapper id={category.toLowerCase()}>
      <NavItems>
        {items.map((el: any, key: number) => (
          <AnchorLink
            to={`/neighborhood#${el.Link.toLowerCase()}`}
            title={el.Link}
            className={category === el.Link ? "active" : ""}
            key={key}
          />
        ))}
      </NavItems>
    </NavBarWrapper>
  );
};

export default NavBar;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      NavBar {
        Link {
          Link
        }
      }
    }
  }
`;
