import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { ImageTextWrapper, Text, Image } from "./index.styled";
import { IImageText } from "../../interfaces/imageText-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ImageText: FC<IImageText> = ({ ...props }) => {
  const imageTextWrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: imageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          textRef?.current || null,
          0.75,
          { opacity: 0 },
          { opacity: 1 },
        )
        .fromTo(
          imageRef?.current || null,
          0.75,
          { opacity: 0 },
          { opacity: 1 },
          "+=.25"
        ),
    });
  }, []);

  const data = useStaticQuery(query);
  let imgSrc;
  let text;

  switch (props.page) {
    case "Residences1":
      imgSrc =
        data.strapiTheResidencesPage.ImageAndText1.Image[0].localFile
          .childImageSharp.gatsbyImageData;
      text = data.strapiTheResidencesPage.ImageAndText1.Text;
      break;
    case "Residences2":
      imgSrc =
        data.strapiTheResidencesPage.ImageAndText2.Image[0].localFile
          .childImageSharp.gatsbyImageData;
      text = data.strapiTheResidencesPage.ImageAndText2.Text;
      break;
    case "Amenities1":
      imgSrc =
        data.strapiTheAmenitiesPage.ImageAndText1.Image[0].localFile
          .childImageSharp.gatsbyImageData;
      text = data.strapiTheAmenitiesPage.ImageAndText1.Text;
      break;
    case "Amenities2":
      imgSrc =
        data.strapiTheAmenitiesPage.ImageAndText2.Image[0].localFile
          .childImageSharp.gatsbyImageData;
      text = data.strapiTheAmenitiesPage.ImageAndText2.Text;
      break;
  }

  return (
    <ImageTextWrapper reversed={props.reversed} ref={imageTextWrapperRef}>
      <Image imgWidth={props.imgWidth} ref={imageRef}>
        <GatsbyImage image={imgSrc} alt={"200East83rd"} />
      </Image>
      {text && (
        <Text className={"body-copy"} textWidth={props.textWidth} ref={textRef}>
          {text}
        </Text>
      )}
    </ImageTextWrapper>
  );
};

export default ImageText;

const query = graphql`
  {
    strapiTheResidencesPage {
      ImageAndText1 {
        Text
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      ImageAndText2 {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheAmenitiesPage {
      ImageAndText1 {
        Text
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      ImageAndText2 {
        Text
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
