import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const MapWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10.3% 48px;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 40px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 0 32px;
  `};
`;

export const Image = styled.div`
  width: 100%;
`;
