import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ArchitectSectionWrapper = styled.div`
  padding: 0 18.43%;
  ${mediaMax.tabletLandscape`
    padding: 0%;
  `}
`;
export const ArchitectSectionContainer = styled.div`
  ${mediaMax.tabletLandscape`
    padding: 0 15.2%;
  `}
  ${mediaMax.phoneXL`
    padding: 0 7%;
  `}
`;
export const ArchitectSectionHeadings = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 48px;
  white-space: pre-line;
  ${mediaMax.tabletLandscape`  
    padding-bottom: 16px;
  `}
  span {
    position: absolute;
    width: 0;
    height: 1px;
    background-color: #000;
    &.top-line {
      left: 17.5%;
    }
    &.bottom-line {
      right: 17.5%;
    }
    ${mediaMax.tabletLandscape`
      width: 100%;
      &.top-line {
        left: 0;
      }
      &.bottom-line {
        right: 0;
      }
    `}
  }
  h3 {
    width: 65%;
    margin: 0 auto;
    padding: 24px 0;
    font: normal normal normal 40px/40px RR CUSTOM;
    letter-spacing: 2.4px;
    color: #000000;
    //border-top: 1px solid #707070;
    ${mediaMax.tabletLandscape`
      width: 100%;
    `}
    ${mediaMax.phoneXL`
      font: normal normal normal 32px/38px RR CUSTOM;
      padding: 18px 0;
    `}
  }
`;

export const ArchitectSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 96px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 40px;
  `}
`;

export const LeftCol = styled.div`
  width: 61.5%;
`;

export const RightCol = styled.div`
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ArchitectTowerContent = styled.div`
  padding-bottom: 96px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 48px;
  `}
  p {
    padding-bottom: 86px;
    text-align: left;
    font: normal normal normal 27px/34px RR CUSTOM;
    color: #000000;
    white-space: pre-line;
    ${mediaMax.tabletLandscape`
      padding-bottom: 48px;
      font: normal normal normal 24px/32px RR CUSTOM;
    `}
    ${mediaMax.phoneXL`
      padding-bottom: 40px;
      font: normal normal normal 19px/24px RR CUSTOM;
    `}
  }
  .gatsby-image-wrapper {
    width: 31%;
    margin: 0 auto;
    ${mediaMax.tabletLandscape`
      width: 38%;
    `};
    ${mediaMax.phoneXL`
      width: 50%;
    `};
  }
`;
export const ArchitectSectionQuotation = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 56px;
  `};
  ${mediaMax.phoneXL`
    padding-bottom: 48px;
  `};
`;

export const QuotationText = styled.div`
  padding-bottom: 12px;
  text-align: center;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 24px/30px RR CUSTOM;
    letter-spacing: 1.5px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 5% 16px;
  `};
`;

export const QuotationAuthor = styled.div`
  text-align: center;
  ${mediaMax.tabletLandscape`
    font: normal normal bold 12px/20px Founders Grotesk;
    letter-spacing: 0.96px;
  `};
`;

export const ReadMoreButton = styled.div`
  text-align: left;
  padding-bottom: 16px;
  margin-bottom: 136px;
  border-bottom: 1px solid;
  cursor: pointer;
  transition: 0.4s ease-out;
  ${mediaMax.tabletLandscape`
    margin-bottom: 56px;
    padding-bottom: 8px;
    font: normal normal 400 14px/20px Founders Grotesk;
    letter-spacing: 1.12px;
  `};
  ${mediaMax.phoneXL`
    margin-bottom: 48px;
    font: normal normal 400 11px/20px Founders Grotesk;
    letter-spacing: 0.96px;
  `};
  span {
    padding-right: 12px;
  }
  &.hide {
    display: none;
  }
`;
