import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useWindowSize from "../../../helpers/UseWindowSize";

import { INeighborhoodImage } from "../../../interfaces/neighborhoodImage-props";
import { ImageImageWrapper, ImageLeft, ImageRight } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ImageImage: FC<INeighborhoodImage> = ({ img }) => {
  const imageImageWrapperRef = useRef<HTMLDivElement>(null);
  const imageLeftRef = useRef<HTMLDivElement>(null);
  const imageRightRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();

  const data = useStaticQuery(query);
  let img1Src;
  let img2Src;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: imageImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          imageLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          imageRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (img) {
    case "Museum1-2":
      img1Src =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      img2Src =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .HalfWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Museum3-4":
      img1Src =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .HalfWidthImage3.localFile.childImageSharp.gatsbyImageData;
      img2Src =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .HalfWidthImage4.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks1-2":
      img1Src =
        data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden
          .HalfWidthImageLeft.localFile.childImageSharp.gatsbyImageData;
      img2Src =
        data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden
          .HalfWidthImageRight.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Markets1-2":
      img1Src =
        data.strapiTheNeighborhoodPage.MarketsSection.ImageImage
          .HalfWidthImageLeft.localFile.childImageSharp.gatsbyImageData;
      img2Src =
        data.strapiTheNeighborhoodPage.MarketsSection.ImageImage
          .HalfWidthImageRight.localFile.childImageSharp.gatsbyImageData;
      break;
  }

  return (
    <ImageImageWrapper ref={imageImageWrapperRef}>
      {size.width > 1025 && (
        <ImageLeft ref={imageLeftRef}>
          <GatsbyImage image={img1Src} alt={"200East83rd"} />
        </ImageLeft>
      )}
      <ImageRight ref={imageRightRef}>
        <GatsbyImage image={img2Src} alt={"200East83rd"} />
      </ImageRight>
    </ImageImageWrapper>
  );
};

export default ImageImage;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        MetropolitanMuseum {
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImage3 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImage4 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        TheConservatoryGarden {
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      MarketsSection {
        ImageImage {
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
