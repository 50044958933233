import styled from "styled-components";

export const FullWidthImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const Image = styled.div`
  width: 100%;
`;
