import styled from "styled-components";

export const ItemImageImageTextWrapper = styled("div")<{ reversed: boolean }>`
  display: flex;
  flex-direction: ${(props: { reversed: boolean }) =>
    props.reversed ? "row-reverse" : "row"};
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ColLeft = styled.div`
  width: 47.8%;
`;

export const ColRight = styled.div`
  width: 47.8%;
`;
