import styled from "styled-components";

export const Item2Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ColLeft = styled.div`
  width: 50%;
  border-right: 1px solid #1a1818;
  padding-right: 2.2%;
`;

export const ColRight = styled.div`
  width: 47.8%;
`;
