import React, { useEffect, useRef, useState, useContext } from "react";
import ReactPlayer, { VimeoPlayerProps } from "react-player/vimeo";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, navigate } from "gatsby";
import {
  VideoWrapper,
  VideoStyled,
  VideoHeader,
  VideoFooter,
} from "./index.styled";

import logo from "../../../images/logo-main-light.svg";
import BaseReactPlayer from "react-player/base";
import { MenuBtn } from "../../header/index.styled";
import { HeaderContext } from "../../layout";

const VideoIntro = () => {
  const [videoPlay] = useState(true);
  const videoRef = useRef<BaseReactPlayer<VimeoPlayerProps>>(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoHeaderRef = useRef<HTMLDivElement>(null);
  const videoFooterRef = useRef<HTMLDivElement>(null);
  const { isMenuActive, setIsMenuActive } = useContext(HeaderContext);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: videoWrapperRef?.current || "",
      start: "top+=5% top",
      end: "bottom top",
      id: "videoIntro",
      toggleActions: "play none none reverse",
      scrub: 1,
      animation: gsap.fromTo(
        videoWrapperRef?.current || null,
        0.4,
        { opacity: 1 },
        { opacity: 0 }
      ),
      onLeaveBack: playVideo,
    });

  }, []);

  const playVideo = () => {
    setTimeout(() => {
      if (videoRef && videoRef.current) {
        videoRef.current.getInternalPlayer().play();
      }
    }, 1000);
  };

  useEffect(() => {
    playVideo();
  }, []);

  return (
    <VideoWrapper id={"videoPlayer"} ref={videoWrapperRef}>
      <VideoHeader ref={videoHeaderRef}>
        <img src={logo} alt="logo" />
        <MenuBtn
          className={"animate"}
          onClick={() => setIsMenuActive(!isMenuActive)}
        >
          <p>MENU</p>
          <span />
        </MenuBtn>
      </VideoHeader>
      <VideoStyled ref={videoContainerRef}>
        <ReactPlayer
          url="https://player.vimeo.com/video/577160963"
          autoPlay={true}
          playing={videoPlay}
          byline="0"
          title="0"
          muted={true}
          loop={true}
          frameBorder="0"
          playsInline={true}
          webkit-playsinline={"true"}
          x5-playsinline={"true"}
          ref={videoRef}
          controls={false}
          config={{
            playerOptions: {
              autoplay: true,
              muted: true,
              background: true,
              playsinline: true,
              loop: true,
              controls: false,
            },
          }}
        />
      </VideoStyled>

      <VideoFooter ref={videoFooterRef}>
        <h5>SCROLL</h5>
        <p>to discover the story</p>
      </VideoFooter>
    </VideoWrapper>
  );
};

export default VideoIntro;
