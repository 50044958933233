import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { INextPageSection } from "../../interfaces/nextPageSection-props";

import {
  NextPageSectionWrapper,
  IntroHorizontalLine,
  NextPageButton,
} from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const NextPageSection: FC<INextPageSection> = ({ ...props }) => {
  const nextPageSectionWrapperRef = useRef<HTMLDivElement>(null);
  const horizontalLineRef = useRef<HTMLDivElement>(null);
  const nextPageButton1Ref = useRef<HTMLParagraphElement>(null);
  const nextPageButton2Ref = useRef<HTMLParagraphElement>(null);

  const data = useStaticQuery(query);
  let title;
  let buttonText;
  let next: any;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: nextPageSectionWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          nextPageButton1Ref?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.5"
        )
        .fromTo(
          nextPageButton2Ref?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.2"
        )
        .fromTo(
          horizontalLineRef?.current || null,
          0.8,
          { width: 0 },
          { width: "30%" },
          "+=.2"
        ),
    });
  }, []);

  switch (props.page) {
    case "theBuildingPage":
      title = data.strapiTheBuildingPage.NextChapterSection.Title;
      buttonText = data.strapiTheBuildingPage.NextChapterSection.ButtonText;
      next = "/residences#theResidencesPage";
      break;
    case "theResidencesPage":
      title = data.strapiTheResidencesPage.NextChapterSection.Title;
      buttonText = data.strapiTheResidencesPage.NextChapterSection.ButtonText;
      next = "/penthouses#thePenthousesPage";
      break;
    case "thePenthousesPage":
      title = data.strapiThePenthousesPage.NextChapterSection.Title;
      buttonText = data.strapiThePenthousesPage.NextChapterSection.ButtonText;
      next = "/amenities#theAmenitiesPage";
      break;
    case "theAmenitiesPage":
      title = data.strapiTheAmenitiesPage.NextChapterSection.Title;
      buttonText = data.strapiTheAmenitiesPage.NextChapterSection.ButtonText;
      next = "/neighborhood#theNeighborhoodPage";
      break;
  }

  return (
    <NextPageSectionWrapper ref={nextPageSectionWrapperRef}>
      <NextPageButton to={next}>
        <p className={"body-title"} ref={nextPageButton1Ref}>
          {title}
        </p>
        <p className={"button-text"} ref={nextPageButton2Ref}>
          {buttonText}
        </p>
      </NextPageButton>
      <IntroHorizontalLine ref={horizontalLineRef} />
    </NextPageSectionWrapper>
  );
};

export default NextPageSection;

const query = graphql`
  {
    strapiTheBuildingPage {
      NextChapterSection {
        ButtonText
        Title
      }
    }
    strapiTheResidencesPage {
      NextChapterSection {
        ButtonText
        Title
      }
    }
    strapiThePenthousesPage {
      NextChapterSection {
        ButtonText
        Title
      }
    }
    strapiTheAmenitiesPage {
      NextChapterSection {
        ButtonText
        Title
      }
    }
  }
`;
