import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "../../helpers/MediaQueries";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 28px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.4s ease-out;
  ${mediaMax.tabletLandscape`
    padding: 32px 24px 0;
  `};
`;
export const HeaderLogo = styled(Link)`
  position: fixed;
  z-index: 10;
  ${mediaMax.tabletLandscape`
    width: 64px; 
  `}
`;
export const HeaderLogoItem = styled.img`
  margin: 0;
  width: 100%;
`;

export const HeaderNavigation = styled.div`
  padding-top: 127px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: -375px;
  top: 0;
  max-width: 375px;
  width: 100%;
  height: 100vh;
  background-color: #000;
  transition: 0.4s ease-out;
  overflow-y: scroll;
  z-index: 151;
  &.active {
    right: 0;
  }
  ${mediaMax.phoneXL`
    max-width: 100%;
    right: -100%;
  `}
`;

export const CloseBtn = styled.img`
  display: block;
  max-width: 32px;
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 24px;
`;

export const HeaderNavigationBlock1 = styled("div")`
  padding-bottom: 50px;
`;
export const HeaderNavigationBlock2 = styled("div")`
  padding-bottom: 60px;
`;
export const HeaderNavigationBlock3 = styled("div")`
  padding-bottom: 50px;
  .insta-icon {
    opacity: 1;
  }
  a {
    padding: 8px 24px;
    text-transform: capitalize;
    opacity: 0.7;
  }
  .menu-link {
    display: block;
    width: max-content;
    position: relative;
    color: #fbf7f2;
    text-decoration: none;
    font: normal normal normal 12px/14px Founders Grotesk;
    letter-spacing: 0.6px;
    padding: 8px 24px;
    transition: 0.4s ease-out;
  }
`;
export const HeaderNavigationLink = styled(Link)`
  display: block;
  width: max-content;
  position: relative;
  color: #fbf7f2;
  text-decoration: none;
  font: normal normal normal 12px/14px Founders Grotesk;
  letter-spacing: 0.6px;
  padding: 8px 24px;
  transition: 0.4s ease-out;
  text-transform: uppercase;
  p {
    font: normal normal normal 24px/24px RR CUSTOM;
    letter-spacing: 0;
    padding-top: 2px;
    text-transform: uppercase;
  }
  &:hover ::after,
  &.active ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    width: 16px;
    height: 1px;
    background: #fbf7f2;
  }
`;

export const MenuBtn = styled.div`
  position: fixed;
  z-index: 10;
  right: 24px;
  font: normal normal normal 14px/14px Founders Grotesk;
  letter-spacing: 1.12px;
  color: #000;
  cursor: pointer;
  padding-top: 8px;
  min-width: 80px;
  text-align: right;

  ${mediaMax.phoneXL`
    position: fixed;
    padding-top: 0;
    height: 12px;
    p {
      display: none;
    }
    span {
      margin-left: auto;
      display: block;
      width: 24px;
      height: 1px;
      background-color: black;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 6px;
          width: 24px;
          height: 1px;
          background-color: black;
        }
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 12px;
          width: 24px;
          height: 1px;
          background-color: black;
        }
    }
  `}
`;
