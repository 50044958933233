import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const TeamItemsWrapper = styled.div`
  padding: 0 10.3% 72px;
  min-height: calc(100vh - 265px);
  ${mediaMax.phoneXL`
    padding: 0 7% 48px;
  `}
`;

export const TeamItem = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  max-width: 600px;
  white-space: pre-line;
  ${mediaMax.tabletLandscape`
    padding: 18px 0;
  `};
  .ROCKEFELLER {
    margin-top: -36px;
  }
`;
export const Title = styled.a`
  display: block;
  text-align: center;
  font: normal normal normal 28px/36px RR CUSTOM;
  letter-spacing: 1.68px;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font-size: 24px;
    line-height: 30px;
  `};
`;
export const SubTitle = styled.div`
  padding-bottom: 12px;
  text-align: center;
  font: normal normal 600 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1a1818;
  text-transform: uppercase;
  ${mediaMax.tabletLandscape`
    font-size: 11px;
    line-height: 12px;
    padding-bottom: 11px;
  `};
`;
export const Description = styled.div`
  padding-bottom: 24px;
  text-align: center;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0;
  color: #1a1818;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal 300 15px/18px Founders Grotesk;
  `};
`;
export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Link = styled.a`
  display: inline-block;
  text-align: center;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1a1818;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 11px/18px Founders Grotesk;
  `};
`;
