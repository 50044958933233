import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const FullWidthImageWrapper = styled("div")<{ withPadding: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(props: { withPadding: boolean }) =>
    props.withPadding ? "0 10.3% 96px" : "0 0 96px 0"};
  ${mediaMax.tabletLandscape`  
    padding: ${(props: { withPadding: boolean }) =>
      props.withPadding ? "0 15.2% 80px" : "0 0 80px 0"};
  `}
  ${mediaMax.phoneXL`
    padding: ${(props: { withPadding: boolean }) =>
      props.withPadding ? "0 7% 48px" : "0 0 48px 0"};
  `}
`;

export const Image = styled.div`
  width: 100%;
`;
