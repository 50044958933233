import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ContactInfoWrapper = styled.div`
  position: fixed;
  left: 28px;
  top: 150px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 80px;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    max-width: 536px;
    margin: 0 auto;
  `};
  ${mediaMax.phoneXL`
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
  `};
`;

export const ContactInfoText = styled.p`
  white-space: pre-line;
  text-align: left;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1a1818;
  text-transform: uppercase;
  ${mediaMax.tabletLandscape`
    position: relative;
    top: 0;
    text-align: center;
    white-space: pre-line;
  `};
  // ${mediaMax.phoneXL`
  //   
  // `};
  a {
    color: #1a1818;
    text-decoration: none;
  }
`;
