import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const FooterWrapper = styled.div`
  padding: 0 3.82% 48px;
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;

  ${mediaMax.tablet`
    flex-wrap: wrap;
  `};

  a {
    text-align: center;
    font: normal normal normal 11px/18px Founders Grotesk;
    letter-spacing: 0.88px;
    color: #969696;
    text-transform: uppercase;
    opacity: 1;
    padding: 0 24px;
    text-decoration: none;
    ${mediaMax.tabletLandscape`
      padding: 0 12px;
    `};
    ${mediaMax.phoneXL`
      padding: 3px 6px;
    `};
  }
`;

export const FooterItems = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal normal 11px/18px Founders Grotesk;
  letter-spacing: 0.88px;
  color: #000000;
  text-transform: uppercase;
`;

export const FooterText = styled.div`
  width: 100%;
  text-align: left;
  font: normal normal normal 11px/18px Founders Grotesk;
  letter-spacing: 0.88px;
  color: #969696;
  text-transform: uppercase;
`;
