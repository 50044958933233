import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  CardWrapper,
  CardItem,
  TextCol,
  ImageCol,
  Title,
  Date,
  Logo,
  Button,
  CardImage,
  Description,
} from "./index.styled";
import useWindowSize from "../../../helpers/UseWindowSize";

const Card = () => {
  const size = useWindowSize();
  const data = useStaticQuery(query);
  const { edges: items } = data.allStrapiArticles;

  return (
    <CardWrapper>
      {items.map((el: any, i: number) => (
        <CardItem key={i}>
          <ImageCol>
            {size.width > 1024 && (
              <GatsbyImage
                image={
                  el.node.ArticleThumbnail.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={"200East83rd"}
              />
            )}
          </ImageCol>
          <TextCol>
            <Date>{el.node.ArticleDate}</Date>
            <Logo>
              <h2>{el.node.ArticleLogo}</h2>
            </Logo>

            <Title>{el.node.ArticleTitle}</Title>

            <CardImage>
              {size.width < 1025 && (
                <GatsbyImage
                  image={
                    el.node.ArticleThumbnail.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={"200East83rd"}
                />
              )}
            </CardImage>
            <Description>{el.node.ArticleDescription}</Description>
            <Button>
              <Link to={`/${el.node.id}`} id={el.node.id}>
                {el.node.ReadMoreButton}
              </Link>
            </Button>
          </TextCol>
        </CardItem>
      ))}
    </CardWrapper>
  );
};

export default Card;

const query = graphql`
  {
    allStrapiArticles(sort: {fields: ArticleDate, order: DESC}) {
      edges {
        node {
          ArticleDate(formatString: "MMMM DD, YYYY", locale: "en")
          ArticleDescription
          ArticleSubtitle
          ArticleText
          ArticleTitle
          ReadMoreButton
          strapiId
          slug
          id
          ArticleLogo
          ArticleThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ArticleMainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
