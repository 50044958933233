import styled from "styled-components";

export const TheMastersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ColLeft = styled.div`
  width: 47.8%;
`;

export const ColRight = styled.div`
  width: 47.8%;
`;
