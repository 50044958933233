import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { FacadeSectionWrapper, FacadeImage } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const FacadeSection: FC = () => {
  const facadeSectionWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: facadeSectionWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          facadeSectionWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  const data = useStaticQuery(query);
  const { ImageAndText2: items } = data.strapiTheBuildingPage;

  return (
    <FacadeSectionWrapper ref={facadeSectionWrapperRef}>
      <FacadeImage>
        <GatsbyImage
          image={items.Image[0].localFile.childImageSharp.gatsbyImageData}
          alt={"200East83rd"}
        />
      </FacadeImage>
    </FacadeSectionWrapper>
  );
};

export default FacadeSection;

const query = graphql`
  {
    strapiTheBuildingPage {
      ImageAndText2 {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
