import styled from "styled-components";

export const PrimpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ColLeft = styled.div`
  width: 47.8%;
`;

export const ColRight = styled.div`
  width: 47.8%;
  div:first-child {
    padding-bottom: 48px;
    border-bottom: 1px solid #1a1818;
    margin-bottom: 48px;
  }
`;
