import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import useWindowSize from "../../../helpers/UseWindowSize";

import { INeighborhoodText1 } from "../../../interfaces/neighborhoodText1-props";
import { Item1Wrapper, ColLeft, ColRight } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const Item1Text: FC<INeighborhoodText1> = ({ name, smallTitle }) => {
  const size = useWindowSize();
  const item1WrapperRef = useRef<HTMLDivElement>(null);
  const colLeftRef = useRef<HTMLDivElement>(null);
  const colRightRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let item;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: item1WrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          colLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          colRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (name) {
    case "Museum":
      item = data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum;
      break;
    case "Auction":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheAuction;
      break;
    case "Memberships":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheMemebership;
      break;
    case "ConservatoryGarden":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden;
      break;
    case "Pursuits":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheAthleticPursuits;
      break;
    case "Paninoteca":
      item = data.strapiTheNeighborhoodPage.DiningSection.ThePaninoteca;
      break;
    case "Bistros":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheBistros;
      break;
    case "Cocktails":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheCocktails;
      break;
    case "Fish":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheFish;
      break;
    case "Grocer":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheYourGrocer;
      break;
    case "IceCream":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheIceCream;
      break;
    case "Kids":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheKids;
      break;
    case "Smells":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheSweetSmells;
      break;
    case "Bangle":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheBangle;
      break;
    case "Home":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheHome;
      break;
    case "Workout":
      item = data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout;
      break;
  }

  return (
    <Item1Wrapper ref={item1WrapperRef}>
      {size.width > 1025 && (
        <ColLeft ref={colLeftRef}>
          <h2 className={smallTitle ? "item-title-s" : "item-title"}>
            {item.Title}
          </h2>
          <p className={"article-copy-16"}>{item.Description}</p>
        </ColLeft>
      )}

      <ColRight ref={colRightRef}>
        <h3 className={"item-name"}>{item.Name}</h3>
        <p className={"item-address"}>{item.Address}</p>
        {size.width < 1025 && (
          <p className={"article-copy-16 mb-24"}>{item.Description}</p>
        )}

        {item.InfoTitle && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle}</h3>
            <p className={"article-copy-16 mb-24"}>{item.InfoDescription}</p>
          </>
        )}
        {item.InfoTitle2 && size.width > 1025 && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle2}</h3>
            <p className={"item-info-description mb-40"}>
              {item.InfoDescription2}
            </p>
          </>
        )}
      </ColRight>
    </Item1Wrapper>
  );
};

export default Item1Text;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        MetropolitanMuseum {
          InfoDescription
          InfoTitle
          Name
          Title
          Description
          Address
        }
        TheAuction {
          InfoDescription
          InfoTitle
          Name
          Title
          Description
          Address
        }
        TheMemebership {
          InfoDescription2
          InfoTitle2
          Name
          Title
          Description
          Address
        }
      }
      ParkSection {
        TheConservatoryGarden {
          Title
          Name
          InfoTitle2
          InfoTitle
          InfoDescription
          InfoDescription2
          Description
          Address
        }
        TheAthleticPursuits {
          Title
          Name
          InfoTitle2
          InfoDescription2
          Description
          Address
        }
      }
      DiningSection {
        ThePaninoteca {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
        }
        TheBistros {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
        }
        TheCocktails {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
        }
      }
      MarketsSection {
        TheFish {
          Address
          Description
          InfoDescription2
          Name
          Title
          InfoTitle2
        }
        TheYourGrocer {
          Address
          Description
          InfoDescription
          InfoDescription2
          InfoTitle
          InfoTitle2
          Name
          Title
        }
        TheIceCream {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
        }
      }
      ShoppingSection {
        TheKids {
          Address
          Description
          InfoDescription2
          Name
          Title
          InfoTitle2
        }
        TheSweetSmells {
          Address
          Description
          InfoDescription
          InfoDescription2
          InfoTitle
          InfoTitle2
          Name
          Title
        }
        TheBangle {
          Address
          Description
          Name
          Title
        }
        TheHome {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
        }
      }
      LifestyleSection {
        TheWorkout {
          Address
          Description
          InfoDescription
          InfoDescription2
          InfoTitle
          InfoTitle2
          Name
          Title
        }
      }
    }
  }
`;
