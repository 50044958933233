import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { IIntroSection } from "../../interfaces/introSection-props";
import {
  IntroSectionWrapper,
  IntroSectionContainer,
  IntroLeft,
  IntroImage,
  IntroRight,
  IntroTitle,
  IntroText,
  IntroVerticalLine,
  IntroHorizontalLine
} from "./index.styled";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const IntroSection: FC<IIntroSection> = ({ ...props }) => {
  const data = useStaticQuery(query);
  let imgSrc;
  let title;
  let description;

  switch (props.page) {
    case "theBuildingPage":
      imgSrc =
        data.strapiTheBuildingPage.IntroSection.Image.localFile.childImageSharp
          .gatsbyImageData;
      title = data.strapiTheBuildingPage.IntroSection.Title;
      description = data.strapiTheBuildingPage.IntroSection.Description;
      break;
    case "theResidencesPage":
      imgSrc =
        data.strapiTheResidencesPage.IntroSection.Image.localFile
          .childImageSharp.gatsbyImageData;
      title = data.strapiTheResidencesPage.IntroSection.Title;
      description = data.strapiTheResidencesPage.IntroSection.Description;
      break;
    case "thePenthousesPage":
      imgSrc =
        data.strapiThePenthousesPage.IntroSection.Image.localFile
          .childImageSharp.gatsbyImageData;
      title = data.strapiThePenthousesPage.IntroSection.Title;
      description = data.strapiThePenthousesPage.IntroSection.Description;
      break;
    case "theAmenitiesPage":
      imgSrc =
        data.strapiTheAmenitiesPage.IntroSection.Image.localFile.childImageSharp
          .gatsbyImageData;
      title = data.strapiTheAmenitiesPage.IntroSection.Title;
      description = data.strapiTheAmenitiesPage.IntroSection.Description;
      break;
    case "theNeighborhoodPage":
      imgSrc =
        data.strapiTheNeighborhoodPage.IntroSection.Image.localFile
          .childImageSharp.gatsbyImageData;
      title = data.strapiTheNeighborhoodPage.IntroSection.Title;
      description = data.strapiTheNeighborhoodPage.IntroSection.Description;
      break;
  }
  const verticalLineRef = useRef<HTMLDivElement>(null);
  const horizontalLineRef = useRef<HTMLDivElement>(null);
  const infoWrapperRef = useRef<HTMLDivElement>(null);
  const infoContainerRef = useRef<HTMLDivElement>(null);
  const introImageRef = useRef<HTMLDivElement>(null);
  const introTitleRef = useRef<HTMLDivElement>(null);
  const introTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: infoWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top bottom",
      animation: gsap.timeline()
        .fromTo(introImageRef?.current || "", 1.25, {y: "145px", opacity: 0}, {y: "0", opacity: 1}, "+=.8")
        .fromTo(introTitleRef?.current || "", 1.25, {y: "145px", opacity: 0}, {y: "0", opacity: 1})
        .fromTo(introTextRef?.current || "", .75, {opacity: "0"}, {opacity: "1"})
        .fromTo(verticalLineRef?.current || "", .75, {height: "0"}, {height: "100%"}, "-=.75")
        .fromTo(horizontalLineRef?.current || "", .75, {width: "0"}, {width: "70%"})
    })
  }, []);
  return (
    <IntroSectionWrapper ref={infoWrapperRef} id={props.page}>
      <IntroSectionContainer ref={infoContainerRef}>
        <IntroLeft>
          <IntroImage ref={introImageRef}>
            <GatsbyImage image={imgSrc} alt={"200East83rd"} />
          </IntroImage>
        </IntroLeft>
        <IntroRight>
          <IntroTitle className={"body-title"} ref={introTitleRef}>{title}</IntroTitle>
          <IntroText className={"body-copy"} ref={introTextRef}>
            {description}
            <IntroHorizontalLine ref={horizontalLineRef} />
          </IntroText>
          <IntroVerticalLine ref={verticalLineRef} />
        </IntroRight>
      </IntroSectionContainer>
    </IntroSectionWrapper>
  );
};

export default IntroSection;

const query = graphql`
  {
    strapiTheBuildingPage {
      IntroSection {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        Description
        Title
      }
    }
    strapiTheResidencesPage {
      IntroSection {
        Description
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiThePenthousesPage {
      IntroSection {
        Description
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheAmenitiesPage {
      IntroSection {
        Description
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheNeighborhoodPage {
      IntroSection {
        Description
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
