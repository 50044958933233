const seoData = {
  building: {
    title: "200 East 83rd - Luxury Condos for Sale Upper East Side NYC | New Condominium Complex UES",
    description: `200 East 83rd is one of the most exquisite architectural buildings 
                  by Robert A.M. Stern Architects. Check out the collection of luxury residences with sweeping views of 
                  New York today!`
  },
  residences: {
    title: "200 East 83rd Street - Upper East Side Luxury Residences",
    description: `Residences at 200 East 83rd are considered as a modern approach with classic proportions. The interiors 
                  of rooms by Rottet Studio are timelessly inspiring. Visit 
                  the website for more details!`
  },
  penthouses: {
    title: "200 East 83rd Street - Upper East Side Penthouses",
    description: `Extraordinary penthouses at 200 East 83rd are surrounded by cityscapes, with double-height ceilings 
                  that create a gracious and sublime living experience. Visit the website for more details!`
  },
  amenities: {
    title: "The Amenities - 200 East 83rd Street",
    description: `Residences at 200 East 83rd include generous amenity interiors 
                  that lead to gardens, loggias, and terraces with skyline views, 
                  natural light, and fresh air. Visit the website for more details!`
  },
  neighborhood: {
    title: "The Neighborhood - 200 East 83rd Street",
    description: `From finding the perfect coffee to visit the most transformative 
                  art, the neighborhood near 200 East 83rd has it all. Visit the website and check out all the essential 
                  stores near 200 East 83rd!`
  },
  team: {
    title: "Team - 200 East 83rd Street",
    description: `200 East 83rd is one of the most beautiful architectural structures designed by Robert A.M. Stern 
                    Architects and developed by Naftali and Rockefeller groups. Visit the website for more details!`
  },
  availability: {
    title: "Availability - 200 East 83rd Street",
    description: `If you are looking for an exquisite and luxury residence in New 
                  York, look no further than 200 East 83rd. Visit the website and check 
                  out the availability of different residential rooms today!`
  },
  contact: {
    title: "Contact - 200 East 83rd Street",
    description: `Want to make your dream of owning a luxury penthouse come 
                  true? 200 East 83rd in New York is waiting for you with spacious and prestigious penthouses. Visit the 
                  website or contact us today!`
  },
  legal: {
    title: "Legal - 200 East 83rd Street",
    description: `The illustrations at 200 East 83rd’s website are artists’ rendering 
                  and representations. The furniture depicted is also for illustrative 
                  purposes only. Read the legal disclaimer today!`
  }
}
export default seoData;
