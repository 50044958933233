import * as React from "react";
import Card from "../components/press/card";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Press = () => {
  return (
    <>
      <Seo page={SeoData.building}/>
      <PageLabel descriptionOnly={"Press"} />
      <Card />
      <Footer />
    </>
  );
};

export default Press;
