import styled from "styled-components";

export const ShoppingWrapper = styled.div`
  margin: 0 10.3%;
`;

export const Item = styled.div`
  border-bottom: 1px solid #1a1818;
  margin-bottom: 48px;
`;
