import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { MapWrapper, Image } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const Map: FC = () => {
  const mapWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: mapWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          mapWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  return (
    <MapWrapper ref={mapWrapperRef}>
      <Image>
        <GatsbyImage
          image={
            data.strapiTheNeighborhoodPage.Map.MapImage.localFile
              .childImageSharp.gatsbyImageData
          }
          alt={"200East83rd"}
        />
      </Image>
    </MapWrapper>
  );
};

export default Map;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      Map {
        MapImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
