import React, { FC } from "react";
import { ContactInfoWrapper, ContactInfoText } from "./index.styled";
import { graphql, useStaticQuery } from "gatsby";

const ContactInfo: FC = () => {
  const data = useStaticQuery(query);
  const { contactInfoText1, contactInfoText2, contactInfoText3 } =
    data.allStrapiTheContactPage.edges[0].node.ContactInfo;
  return (
    <ContactInfoWrapper>
      <ContactInfoText dangerouslySetInnerHTML={{ __html: contactInfoText1 }} />
      <ContactInfoText dangerouslySetInnerHTML={{ __html: contactInfoText2 }} />
      <ContactInfoText dangerouslySetInnerHTML={{ __html: contactInfoText3 }} />
    </ContactInfoWrapper>
  );
};

const query = graphql`
  {
    allStrapiTheContactPage {
      edges {
        node {
          ContactInfo {
            contactInfoText1
            contactInfoText2
            contactInfoText3
          }
        }
      }
    }
  }
`;

export default ContactInfo;
