import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { INeighborhoodImageImageText } from "../../../interfaces/neighborhoodImageImageText-props";
import { ItemImageImageTextWrapper, ColLeft, ColRight } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ItemImageImageText: FC<INeighborhoodImageImageText> = ({
  name,
  reversed,
}) => {
  const itemImageImageTextWrapperRef = useRef<HTMLDivElement>(null);
  const colLeftRef = useRef<HTMLDivElement>(null);
  const colRightRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let item;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: itemImageImageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          colLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          colRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (name) {
    case "books":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheBooks;
      break;
    case "steak":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheGreatSteak;
      break;
    case "frock":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheFrock;
      break;
  }
  return (
    <ItemImageImageTextWrapper
      reversed={reversed}
      ref={itemImageImageTextWrapperRef}
    >
      <ColLeft ref={colLeftRef}>
        <GatsbyImage
          image={
            item.HalfWidthImageLeft.localFile.childImageSharp.gatsbyImageData
          }
          alt={"200East83rd"}
        />
      </ColLeft>
      <ColRight ref={colRightRef}>
        <GatsbyImage
          className={"mb-48"}
          image={
            item.HalfWidthImageRight.localFile.childImageSharp.gatsbyImageData
          }
          alt={"200East83rd"}
        />
        <h2 className={"item-title"}>{item.Title}</h2>
        <p className={"article-copy-16 mb-24"}>{item.Description}</p>
        <h3 className={"item-name"}>{item.Name}</h3>
        <p className={"item-address"}>{item.Address}</p>
        {item.InfoTitle && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle}</h3>
            <p className={"article-copy-16 mb-24"}>{item.InfoDescription}</p>
          </>
        )}
        {item.InfoTitle2 && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle2}</h3>
            <p className={"item-info-description"}>{item.InfoDescription2}</p>
          </>
        )}
      </ColRight>
    </ItemImageImageTextWrapper>
  );
};

export default ItemImageImageText;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        TheBooks {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          InfoTitle2
          InfoDescription2
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        TheGreatSteak {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ShoppingSection {
        TheFrock {
          Address
          Description
          InfoDescription2
          InfoTitle2
          Name
          Title
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          HalfWidthImageRight {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
