import * as React from "react";
import LegalItems from "../components/legal";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Legal = () => {
  return (
    <>
      <Seo page={SeoData.legal}/>
      <PageLabel descriptionOnly={"Legal"} />
      <LegalItems />
      <Footer />
    </>
  );
};

export default Legal;
