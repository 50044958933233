import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { HalfWidthImageWrapper, Image } from "./index.styled";
import { IHalfWidthImage } from "../../interfaces/halfWidthImage-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const HalfWidthImage: FC<IHalfWidthImage> = ({ ...props }) => {
  const halfWidthImageWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let imgSrc;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: halfWidthImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          halfWidthImageWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (props.img) {
    case "PenthousesImg1":
      imgSrc =
        data.strapiThePenthousesPage.HalfWidthImage.Image.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg2":
      imgSrc =
        data.strapiTheAmenitiesPage.HalfWidthImage2.Image.localFile
          .childImageSharp.gatsbyImageData;
      break;
  }

  return (
    <HalfWidthImageWrapper
      position={props.position}
      ref={halfWidthImageWrapperRef}
    >
      <Image width={props.width}>
        <GatsbyImage image={imgSrc} alt={"200East83rd"} />
      </Image>
    </HalfWidthImageWrapper>
  );
};

export default HalfWidthImage;

const query = graphql`
  {
    strapiThePenthousesPage {
      HalfWidthImage {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheAmenitiesPage {
      HalfWidthImage2 {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
