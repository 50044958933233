import styled from "styled-components";
import {mediaMax, mediaMaxTablet, mediaMaxTabletLandscape} from "../../../helpers/MediaQueries";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 150;
  background: #FBF7F2;
`;
export const VideoStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  background: #FBF7F2;

  iframe {
    width: 100% !important;
    height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
`;

export const VideoHeader = styled.div`
  width: 100%;
  margin: 32px 28px 0;
  position: absolute;
  z-index: 101;
  ${mediaMax.tabletLandscape`
    margin: 32px 24px 0;
    img {
      width: 64px;
    }
  `};
  .animate {
    color: #fbf7f2;
    top: 32px;
    span {
      background-color: #fbf7f2;
      &:before {
        background-color: #fbf7f2;
      }
      &:after {
        background-color: #fbf7f2;
      }
    }
  }
`;

export const VideoFooter = styled.div`
  position: absolute;
  z-index: 101;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  h5 {
    font: normal normal normal 60px/24px RR CUSTOM;
    letter-spacing: 2.4px;
    color: #fbf7f2;
    padding-bottom: 20px;
  }
  p {
    font: normal normal 600 14px/24px Founders Grotesk;
    letter-spacing: 0.56px;
    color: #fbf7f2;
  }
  ${mediaMaxTabletLandscape.tabletLandscape`
    bottom: 22px;
    h5 {
      font: normal normal normal 30px/24px RR CUSTOM;
      padding-bottom: 0;
    }
    p {
      font: normal normal 600 12px/24px Founders Grotesk;
      letter-spacing: 0.56px;
      color: #fbf7f2;
    }
  `};
`;
