import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import useWindowSize from "../helpers/UseWindowSize";

import PageLabel from "../components/page-label";
import IntroSection from "../components/intro-section";
import Map from "../components/neighborhood/map";
import NavBar from "../components/neighborhood/nav-bar";
import Culture from "../components/neighborhood/culture";
import Parks from "../components/neighborhood/parks";
import Dining from "../components/neighborhood/dining";
import Markets from "../components/neighborhood/markets";
import Shopping from "../components/neighborhood/shopping";
import Lifestyle from "../components/neighborhood/lifestyle";
import ResponsiveItem from "../components/neighborhood/resp-item";
import ResponsiveImage from "../components/neighborhood/resp-image";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Neighborhood = () => {
  const size = useWindowSize();
  const [mobile, setMobile] = useState(null);

  useEffect(() => {
    setMobile(size.width < 1025);
  }, [size.width]);

  return (
    <>
      <Seo page={SeoData.neighborhood}/>
      <PageLabel name={"Chapter FIVE"} description={"The NEIGHBORHOOD"} />
      <IntroSection page={"theNeighborhoodPage"} />
      <Map />
      {!mobile ? (
        <>
          <NavBar category={"Culture"} />
          <Culture />
          <NavBar category={"Parks"} />
          <Parks />
          <NavBar category={"Dining"} />
          <Dining />
          <NavBar category={"Markets"} />
          <Markets />
          <NavBar category={"Shopping"} />
          <Shopping />
          <NavBar category={"Lifestyle"} />
          <Lifestyle />
        </>
      ) : (
        <>
          <NavBar category={"Culture"} />
          <ResponsiveItem name={"metropolitan"} border={true} />
          <ResponsiveItem name={"guggenheim"} border={true} />
          <ResponsiveItem name={"jewish"} border={true} />
          <ResponsiveItem name={"books"} border={true} />
          <ResponsiveItem name={"auction"} border={false} />
          <ResponsiveImage img={"culture"} />

          <NavBar category={"Parks"} />
          <ResponsiveItem name={"garden"} border={true} />
          <ResponsiveItem name={"bridge"} border={true} />
          <ResponsiveItem name={"zoo"} border={false} />
          <ResponsiveImage img={"parks"} />

          <NavBar category={"Dining"} />
          <ResponsiveItem name={"sushi"} border={true} />
          <ResponsiveItem name={"brunch"} border={true} />
          <ResponsiveItem name={"bluestone"} border={true} />
          <ResponsiveItem name={"quatorze"} border={false} />
          <ResponsiveImage img={"dining"} />

          <NavBar category={"Markets"} />
          <ResponsiveItem name={"elis"} border={true} />
          <ResponsiveItem name={"lobels"} border={true} />
          <ResponsiveItem name={"iceCream"} border={false} />
          <ResponsiveImage img={"markets"} />

          <NavBar category={"Shopping"} />
          <ResponsiveItem name={"ralph"} border={true} />
          <ResponsiveItem name={"acne"} border={true} />
          <ResponsiveItem name={"fueguia"} border={false} />
          <ResponsiveImage img={"shopping"} />

          <NavBar category={"Lifestyle"} />
          <ResponsiveItem id={"lifestyle"} name={"polish"} border={true} />
          <ResponsiveItem name={"workout"} border={false} />
          <ResponsiveImage img={"lifestyle"} />
        </>
      )}
      <Footer />
    </>
  );
};

export default Neighborhood;
