import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  ArchitectSectionWrapper,
  ArchitectSectionContainer,
  ArchitectSectionHeadings,
  ArchitectSectionContent,
  ArchitectTowerContent,
  LeftCol,
  RightCol,
  ArchitectSectionQuotation,
  QuotationText,
  QuotationAuthor,
  ReadMoreButton,
} from "./index.styled";
import ArchitectArticle from "./article";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ArchitectSection: FC = () => {
  const architectSectionWrapperRef = useRef<HTMLDivElement>(null);
  const architectSectionHeadingsRef = useRef<HTMLDivElement>(null);
  const leftColRef = useRef<HTMLDivElement>(null);
  const rightColRef = useRef<HTMLDivElement>(null);
  const architectTowerContentRef = useRef<HTMLDivElement>(null);
  const architectSectionQuotationRef = useRef<HTMLDivElement>(null);
  const readMoreButtonRef = useRef<HTMLDivElement>(null);
  const topLineRef = useRef<HTMLSpanElement>(null);
  const bottomLineRef = useRef<HTMLSpanElement>(null);

  const data = useStaticQuery(query);
  const { ArchitectSection: items } = data.strapiTheBuildingPage;

  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 1026px)":
        () => {
          ScrollTrigger.create({
            trigger: architectSectionWrapperRef?.current || "",
            toggleActions: "play none none reverse",
            start: "top+=10% bottom",
            animation: gsap
              .timeline({
                defaults: {
                  ease: 'circ'
                }
              })
              .fromTo(
                architectSectionHeadingsRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.15"
              )
              .fromTo(
                topLineRef?.current || null,
                1,
                { width: 0, ease: "in-out" },
                { width: "65%", ease: "in-out" },
                "+=0.1"
              )
              .fromTo(
                bottomLineRef?.current || null,
                1,
                { width: 0, ease: "in-out" },
                { width: "65%", ease: "in-out" },
                "-=1"
              )
              .fromTo(
                leftColRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.1"
              )
              .fromTo(
                rightColRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.1"
              ),
          });
        },
      "(max-width: 1025px)":
        () => {
          ScrollTrigger.create({
            trigger: architectSectionWrapperRef?.current || "",
            toggleActions: "play none none reverse",
            start: "top+=10% bottom",
            animation: gsap
              .timeline()
              .fromTo(
                architectSectionHeadingsRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.15"
              )
              .fromTo(
                topLineRef?.current || null,
                1,
                { width: 0 },
                { width: "100%" },
                "+=0.1"
              )
              .fromTo(
                bottomLineRef?.current || null,
                1,
                { width: 0 },
                { width: "100%" },
                "-=1"
              )
              .fromTo(
                leftColRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.1"
              )
              .fromTo(
                rightColRef?.current || null,
                0.8,
                { opacity: 0 },
                { opacity: 1 },
                "+=.1"
              ),
          });
        },
    });


    ScrollTrigger.create({
      trigger: architectTowerContentRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          architectTowerContentRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
    });
    ScrollTrigger.create({
      trigger: architectSectionQuotationRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          architectSectionQuotationRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
        )
        .fromTo(
          readMoreButtonRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=1"
        ),
    });
  }, []);

  const showArticle = () => {
    document.querySelector("#article")?.classList.add("active");
    document.querySelector(".button-text")?.classList.add("hide");
  };

  return (
    <ArchitectSectionWrapper ref={architectSectionWrapperRef}>
      <ArchitectSectionContainer>
        <ArchitectSectionHeadings ref={architectSectionHeadingsRef}>
          <span className={"top-line"} ref={topLineRef}/>
          <h3>{items.Title}</h3>
          <span className={"bottom-line"} ref={bottomLineRef}/>
          <h3>{items.SubTitle}</h3>
        </ArchitectSectionHeadings>

        <ArchitectSectionContent>
          <LeftCol ref={leftColRef}>
            <GatsbyImage
              image={items.ImageLeft.localFile.childImageSharp.gatsbyImageData}
              alt={"200East83rd"}
            />
          </LeftCol>
          <RightCol ref={rightColRef}>
            <GatsbyImage
              image={
                items.ImageRight1.localFile.childImageSharp.gatsbyImageData
              }
              alt={"200East83rd"}
            />
            <GatsbyImage
              image={
                items.ImageRight2.localFile.childImageSharp.gatsbyImageData
              }
              alt={"200East83rd"}
            />
          </RightCol>
        </ArchitectSectionContent>

        <ArchitectTowerContent ref={architectTowerContentRef}>
          <p>{items.Description}</p>
          <GatsbyImage
            image={items.ImageCenter.localFile.childImageSharp.gatsbyImageData}
            alt={"200East83rd"}
          />
        </ArchitectTowerContent>

        <ArchitectSectionQuotation ref={architectSectionQuotationRef}>
          <QuotationText className={"body-title"}>
            {items.ArchitectQuotation.QuotationText}
          </QuotationText>
          <QuotationAuthor className={"quotation-author-18"}>
            {items.ArchitectQuotation.QuotationAuthor}
          </QuotationAuthor>
        </ArchitectSectionQuotation>

        <ReadMoreButton
          className={"button-text"}
          onClick={showArticle}
          ref={readMoreButtonRef}
        >
          <span>+</span>
          {items.ReadMoreButtonText.ButtonText}
        </ReadMoreButton>
      </ArchitectSectionContainer>

      <ArchitectArticle />
    </ArchitectSectionWrapper>
  );
};

export default ArchitectSection;

const query = graphql`
  {
    strapiTheBuildingPage {
      id
      ArchitectSection {
        Description
        SubTitle
        Title
        ArchitectQuotation {
          QuotationText
          QuotationAuthor
        }
        ImageLeft {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        ImageRight1 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        ImageRight2 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        ImageCenter {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        ReadMoreButtonText {
          ButtonText
        }
      }
    }
  }
`;
