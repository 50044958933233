import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  LegalItemsWrapper,
  Title,
  Description,
  SubTitle,
  SubDescription,
} from "./index.styled";

const LegalItems = () => {
  const data = useStaticQuery(query);
  const { strapiTheLegalPage: items } = data;

  return (
    <LegalItemsWrapper>
      <Title>{items.LegalTitle}</Title>
      <Description>{items.LegalDescription}</Description>
      <SubTitle>{items.ImageCreditsTitle}</SubTitle>
      <SubDescription>{items.ImageCreditsDescription}</SubDescription>
    </LegalItemsWrapper>
  );
};

export default LegalItems;

const query = graphql`
  {
    strapiTheLegalPage {
      LegalTitle
      LegalDescription
      ImageCreditsTitle
      ImageCreditsDescription
      id
    }
  }
`;
