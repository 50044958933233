import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const CardWrapper = styled.div`
  padding: 0 10.3% 72px;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 80px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 80px;
  `};
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  margin: 0 auto;
  max-width: 856px;
  white-space: pre-line;
  border-bottom: 1px solid #000000;
  ${mediaMax.tabletLandscape`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  `};
`;

export const ImageCol = styled.div`
  width: 29%;
`;

export const TextCol = styled.div`
  width: 68%;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `};
`;

export const Date = styled.div`
  padding-bottom: 8px;
  text-align: left;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1a1818;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    text-align: center;
  `};
`;

export const Logo = styled.div`
  margin: 16px 0 24px;
  width: 100%;
  h2 {
    text-align: left;
    font: normal normal normal 16px/20px RR CUSTOM;
    letter-spacing: 1.2px;
    color: #1A1818;
    text-transform: uppercase;
  }
  ${mediaMax.tabletLandscape`
    h2 {
      text-align: center;
    }
  `};
`;

export const Title = styled.div`
  padding-bottom: 16px;
  text-align: left;
  font: normal normal normal 3.5vh/4.5vh RR CUSTOM;
  letter-spacing: 1.68px;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 26px/30px RR CUSTOM;
    text-align: center;
    padding-bottom: 24px;
  `};
  ${mediaMax.phoneXL`
    font: normal normal normal 24px/28px RR CUSTOM;
  `};
`;

export const CardImage = styled.div`
  width: 230px;
  margin: 0 auto 24px;
  ${mediaMax.phoneXL`
    width: 208px;
  `};
`;

export const Description = styled.div`
  padding-bottom: 24px;
  text-align: left;
  font: normal normal normal 2vh/2.75vh Founders Grotesk;
  letter-spacing: 0;
  color: #1a1818;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 160x/18px Founders Grotesk;
  `};
  ${mediaMax.phoneXL`
    font: normal normal normal 15px/18px Founders Grotesk;
  `};
`;

export const Button = styled.div`
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1a1818;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    text-align: center;
  `};
  a {
    color: #1a1818;
  }
`;
