import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const RamsaSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 18.43% 96px;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 56px;
  `};
  ${mediaMax.phoneXL`
    flex-direction: column;
    flex-direction: column-reverse;
    padding: 0 7% 48px;
  `};
`;

export const RamsaImage = styled.div`
  width: 48%;
  ${mediaMax.tabletLandscape`
     width: 48.5%;
  `};
  ${mediaMax.phoneXL`
    margin: 0 auto;
    width: 66%;
  `};
`;

export const RamsaText = styled.div`
  width: 46%;
  ${mediaMax.tabletLandscape`
    width: 48.5%;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
    padding-bottom: 48px;
  `};
`;
