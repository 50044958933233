import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const IntroSectionWrapper = styled.div`
  padding: 26px 10.3% 180px;
  overflow: hidden;
  ${mediaMax.tabletLandscape`
    padding: 26px 15.1% 80px;
  `}
  ${mediaMax.phoneXL`
    padding: 26px 7% 48px;
  `}
`;

export const IntroSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaMax.tabletLandscape`
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
`;

export const IntroLeft = styled.div`
  width: 49%;
  ${mediaMax.tabletLandscape`
      width: 100%; 
    `}
`;

export const IntroImage = styled.div`
  width: 73.5%;
  margin-left: auto;
  ${mediaMax.tabletLandscape`
      margin: 0 auto; 
      width: 100%;
    `}
`;

export const IntroRight = styled.div`
  width: 49%;
  ${mediaMax.tabletLandscape`
      width: 100%;
    `}
`;

export const IntroTitle = styled.h2`
  display: block;
  padding-bottom: 24px;
  max-width: 496px;
  ${mediaMax.tabletLandscape`
    padding: 40px 0;
    width: 100%;
    max-width: 100%;
    text-align: center;
  `}
  ${mediaMax.phoneXL`
    padding: 24px 0 16px;
    width: 100%;
    text-align: center;
  `}
`;

export const IntroText = styled.div`
  padding-bottom: 24px;
  max-width: 496px;
  ${mediaMax.tabletLandscape`
    text-align: center;
    max-width: 100%;
    padding-bottom: 40px;
    position: relative;
  `}
  ${mediaMax.phoneXL`
    text-align: center;
    padding-bottom: 16px;
    position: relative;
  `}
`;

export const IntroHorizontalLine = styled.div`
  display: none;
  ${mediaMax.tabletLandscape`
    display: block;  
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 0;
    background-color: #000;
  `}
`;
export const IntroVerticalLine = styled.div`
  height: 0;
  border-left: 1px solid #000;
  transition: 1s ease-out;
`;
