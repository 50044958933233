import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { TextFieldWrapper, Text } from "./index.styled";
import { ITextField } from "../../interfaces/textField-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const TextField: FC<ITextField> = ({ ...props }) => {
  const textFieldWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let text;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: textFieldWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          textFieldWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (props.page) {
    case "Building1":
      text = data.strapiTheBuildingPage.TextField1.Text;
      break;
    case "Building2":
      text = data.strapiTheBuildingPage.TextField2.Text;
      break;
    case "Residences1":
      text = data.strapiTheResidencesPage.TextField.Text;
      break;
    case "Penthouses1":
      text = data.strapiThePenthousesPage.TextField.Text;
      break;
    case "Amenities1":
      text = data.strapiTheAmenitiesPage.TextField1.Text;
      break;
  }

  return (
    <TextFieldWrapper ref={textFieldWrapperRef}>
      <Text className={"body-copy"}>{text}</Text>
    </TextFieldWrapper>
  );
};

export default TextField;

const query = graphql`
  {
    strapiTheResidencesPage {
      TextField {
        Text
      }
    }
    strapiThePenthousesPage {
      TextField {
        Text
      }
    }
    strapiTheAmenitiesPage {
      TextField1 {
        Text
      }
    }
    strapiTheBuildingPage {
      TextField1 {
        Text
      }
      TextField2 {
        Text
      }
    }
  }
`;
