import styled from "styled-components";

export const ItemImageTextWrapper = styled("div")<{ reversed: boolean }>`
  display: flex;
  flex-direction: ${(props: { reversed: boolean }) =>
    props.reversed ? "row-reverse" : "row"};
  justify-content: space-between;
  padding-bottom: 48px;
`;

export const ColLeft = styled("div")<{ textWidth: string }>`
  width: ${(props: { textWidth: string }) =>
    props.textWidth ? props.textWidth : "47.8%"};
`;

export const ColRight = styled("div")<{ imgWidth: string }>`
  width: ${(props: { imgWidth: string }) =>
    props.imgWidth ? props.imgWidth : "47.8%"};
`;
