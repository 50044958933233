import * as React from "react";
import TeamItems from "../components/team/team-items";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Team = () => {
  return (
    <>
      <Seo page={SeoData.team}/>
      <PageLabel descriptionOnly={"Team"} />
      <TeamItems />
      <Footer />
    </>
  );
};

export default Team;
