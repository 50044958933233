import * as React from "react";
import useWindowSize from "../helpers/UseWindowSize";
import ContactBody from "../components/contact/contact-body";
import ContactInfo from "../components/contact/contact-info";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Contact = () => {
  const size = useWindowSize();

  return (
    <>
      <Seo page={SeoData.contact}/>
      <PageLabel descriptionOnly={"Contact"} />
      {size.width > 1025 && <ContactInfo />}
      <ContactBody />
      {size.width <= 1025 && <ContactInfo />}
      <Footer />
    </>
  );
};

export default Contact;
