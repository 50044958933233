import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { INeighborhoodImage } from "../../../interfaces/neighborhoodImage-props";
import { FullWidthImageWrapper, Image } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ResponsiveImage: FC<INeighborhoodImage> = ({ img }) => {
  const fullscreenImageWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let imgSrc;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: fullscreenImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          fullscreenImageWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (img) {
    case "culture":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .FullWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
    case "parks":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.FullWidthImage1
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "dining":
      imgSrc =
        data.strapiTheNeighborhoodPage.DiningSection.FullWidthImage1
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "markets":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.TheBowBridge.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "shopping":
      imgSrc =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheShoes.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "lifestyle":
      imgSrc =
        data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout
          .FullWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
  }

  return (
    <FullWidthImageWrapper ref={fullscreenImageWrapperRef}>
      <Image>
        <GatsbyImage image={imgSrc} alt={"200East83rd"} />
      </Image>
    </FullWidthImageWrapper>
  );
};

export default ResponsiveImage;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        MetropolitanMuseum {
          FullWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        FullWidthImage1 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        FullWidthImage1 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        TheBowBridge {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ShoppingSection {
        TheShoes {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      LifestyleSection {
        TheWorkout {
          FullWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
