import React, { FC } from "react";
import { DiningWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import Item1Text from "../item1-text";
import Item2Text from "../item2-text";
import ItemImageText from "../item-image-text";
import ItemImageImageText from "../item-image-imageText";

const Dining: FC = () => {
  return (
    <DiningWrapper>
      <Item>
        <ItemImageText
          name={"omakase"}
          reversed={true}
          imgWidth={"67.1%"}
          textWidth={"28.5%"}
        />
      </Item>
      <Item>
        <ItemImageText
          name={"trattorias"}
          reversed={false}
          imgWidth={"59.3%"}
          textWidth={"31.9%"}
        />
      </Item>
      <Item>
        <ItemImageText
          name={"coffee"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <ItemImageText
          name={"brunch"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Item1Text name={"Paninoteca"} smallTitle={true} />
      </Item>
      <Item>
        <ItemImageText
          name={"burger"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Item2Text name={"Slice"} smallTitle={true} />
      </Item>
      <Item>
        <Item1Text name={"Bistros"} />
        <FullWidthImage img={"Dining1"} />
      </Item>
      <Item>
        <ItemImageImageText name={"steak"} reversed={false} />
      </Item>
      <Item>
        <Item1Text name={"Cocktails"} />
      </Item>
      <FullWidthImage img={"Dining2"} />
    </DiningWrapper>
  );
};

export default Dining;
