import React, { useEffect, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AvailabilityItemsWrapper, NoItemsWrapper, Card } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import useWindowSize from "../../../helpers/UseWindowSize";

const AvailabilityItems = () => {
  return (
    <AvailabilityItemsWrapper>
      <NoItemsWrapper>PLEASE INQUIRE<br />FOR AVAILABILITY</NoItemsWrapper>
    </AvailabilityItemsWrapper>
  )
}

// const AvailabilityItems = () => {
//   const [priceSortASC, setPriceSortASC] = useState(true);
//   const [bedsSortASC, setBedsSortASC] = useState(true);
//   const size = useWindowSize();

//   const availabilityItemsWrapperRef = useRef<HTMLDivElement>(null);
//   const cardRef = useRef<HTMLDivElement>(null);

//   const data = useStaticQuery(query);
//   const { AvailabilityItem: items } = data.strapiTheAvailabilityPage;

//   function numberWithCommas(x: any) {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   }

//   useEffect(() => {
//     ScrollTrigger.create({
//       trigger: availabilityItemsWrapperRef?.current || "",
//       toggleActions: "play none none reverse",
//       start: "top bottom",
//       animation: gsap
//         .timeline()
//         .fromTo(
//           cardRef?.current || "",
//           1,
//           { opacity: 0 },
//           { opacity: 1 },
//           "+=.1"
//         )
//         .fromTo(".card", 1, { opacity: 0 }, { opacity: 1 }, "+=.1"),
//     });
//   }, []);

//   const bedsASC = () =>
//     items.sort((a: { Beds: number }, b: { Beds: number }) => a.Beds - b.Beds);
//   const bedsDESC = () =>
//     items.sort((a: { Beds: number }, b: { Beds: number }) => b.Beds - a.Beds);
//   const priceASC = () =>
//     items.sort(
//       (a: { Price: number }, b: { Price: number }) => a.Price - b.Price
//     );
//   const priceDESC = () =>
//     items.sort(
//       (a: { Price: number }, b: { Price: number }) => b.Price - a.Price
//     );

//   return (
//     <AvailabilityItemsWrapper ref={availabilityItemsWrapperRef}>
//       <Card className={"headings"} ref={cardRef}>
//         <p>Unit</p>
//         <p
//           className={bedsSortASC ? "wide sort" : "wide sort rotate"}
//           onClick={() => {
//             setBedsSortASC(!bedsSortASC);
//             bedsSortASC ? bedsASC() : bedsDESC();
//           }}
//         >
//           Beds / Baths
//         </p>
//         <p className={"wide intExt"}>Int. SF / Ext. SF</p>
//         <p className={"wide"}>Exposure</p>
//         <p
//           className={priceSortASC ? "wide sort" : "wide sort rotate"}
//           onClick={() => {
//             setPriceSortASC(!priceSortASC);
//             priceSortASC ? priceASC() : priceDESC();
//           }}
//         >
//           Price
//         </p>
//         <p>CC</p>
//         <p>Ret</p>
//         <p className={"wide"}>Floor Plan</p>
//       </Card>
//       {items.map((el: any) => (
//         <Card key={el.id} className={"card"}>
//           <p className={"unit"}>{el.Unit}</p>
//           <p className={"wide"}>
//             {el.Beds} {size.width < 1025 && "BEDS"} / {el.Baths}{" "}
//             {size.width < 1025 && "BATHS"}
//           </p>
//           <p className={"wide intExt"}>
//             {el.Int} {size.width < 1025 && "INT. SF"} / {el.Ext}{" "}
//             {size.width < 1025 && "EXT. SF"}
//           </p>
//           <p className={"wide"}>
//             {el.Exposure} {size.width < 1025 && "EXPOSURE"}
//           </p>
//           <p className={"wide"}> ${numberWithCommas(el.Price)}</p>
//           <p className={"light"}>
//             ${numberWithCommas(el.CC)} {size.width < 1025 && "CC"}
//           </p>
//           <p className={"light"}>
//             ${numberWithCommas(el.Ret)} {size.width < 1025 && "RET"}
//           </p>
//           <p className={"wide bold btn"}>
//             <a target={"_blank"} href={el.FloorPlanFile?.localFile.url}>
//               {el.FloorPlan}
//             </a>
//           </p>
//         </Card>
//       ))}
//     </AvailabilityItemsWrapper>
//   );
// };

export default AvailabilityItems;

// const query = graphql`
//   {
//     strapiTheAvailabilityPage {
//       AvailabilityItem {
//         Beds
//         Baths
//         CC
//         Exposure
//         FloorPlan
//         Int
//         Ext
//         Unit
//         id
//         Price
//         Ret
//         FloorPlanFile {
//           localFile {
//             id
//             publicURL
//             url
//           }
//         }
//       }
//     }
//   }
// `;
