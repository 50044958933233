import React, { FC } from "react";
import styled from "styled-components";
import { IPageLabelProps } from "../../interfaces/page-label-props";
import { mediaMax } from "../../helpers/MediaQueries";

const PageLabelStyled = styled.div`
  padding-top: 42px;
  padding-bottom: 83px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  text-align: center;
  h5 {
    font: normal normal normal 14px/14px Founders Grotesk;
    ${mediaMax.tabletLandscape`
      normal normal normal 12px/12px Founders Grotesk;
    `};
  }
  h1 {
    font: normal normal normal 14px/14px RR CUSTOM;
    &.h1 {
      font: normal normal normal 14px/14px Founders Grotesk;
      ${mediaMax.tabletLandscape`
        normal normal normal 12px/12px Founders Grotesk;
      `};
    }
  }
`;

const PageLabel: FC<IPageLabelProps> = ({ name, description, descriptionOnly }) => {
  return (
    <PageLabelStyled id={"page-label"}>
      {name && <h5>{name}</h5>}
      {description && <h1>{description}</h1>}
      {descriptionOnly && <h1 className={'h1'}>{descriptionOnly}</h1>}
    </PageLabelStyled>
  );
};

export default PageLabel;
