import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const AvailabilityItemsWrapper = styled.div`
  padding: 0 20px 98px;
  margin: 0 auto;
  max-width: 910px;
  min-height: calc(100vh - 273px);
  ${mediaMax.tabletLandscape`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `};
`;

export const NoItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  padding-top: 60px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 0 22px 22px;
  margin: 8px;
  border-bottom: 1px solid #000;

  ${mediaMax.tabletLandscape`
    width: 260px;
    padding: 40px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    background: rgba(26, 24, 24, 0.05);
    &.headings {
      display: none;
    }
  `};
  ${mediaMax.phoneXL`
    width: 100%;
  `};
  p {
    width: 10%;
    padding: 0 10px;
    text-align: left;
    font: normal normal normal 12px/20px Founders Grotesk;
    letter-spacing: 0.96px;
    color: #000000;
    text-transform: uppercase;
    line-height: 1;

    ${mediaMax.tabletLandscape`
      width: 100%!important;
      line-height: 2.2;
    `};
    a {
      text-decoration: none;
      color: #000000;
      transition: 0.4s ease-out;
      &:hover {
        opacity: 0.75;
      }
    }
    &.wide {
      width: 14%;
    }
    &.wide.sort {
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -3px;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        border: 1px solid black;
        transform: rotate(45deg);
        border-top-color: transparent;
        border-left-color: transparent;
        transition: 0.4s ease-out;
      }
    }
    &.wide.sort.rotate {
      &:after {
        top: 3px;
        transform: rotate(225deg);
      }
    }
    &.intExt {
      width: 18%;
    }
    &.btn {
      font-weight: 600;
      ${mediaMax.tabletLandscape`
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 42px;
        background: #000;
        a {
          color: #fff;
        }
      `};
    }
    &.unit {
      font: normal normal normal 30px/14px RR CUSTOM;
      letter-spacing: 2.4px;
      ${mediaMax.tabletLandscape`
        font: normal normal normal 24px/28px RR CUSTOM;
        padding-bottom: 20px;
      `};
    }
    &.light {
      color: #969696;
    }
  }
`;
