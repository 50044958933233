import styled from "styled-components";

export const FacadeSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 96px;
`;

export const FacadeImage = styled.div`
  width: 100%;
`;

export const FacadeText = styled.div`
  white-space: pre-line;
  width: 28.5%;
`;
