import * as React from "react";
import IntroSection from "../components/intro-section";
import FullWidthImage from "../components/full-width-image";
import ImageText from "../components/image-text";
import NextPageSection from "../components/next-page-section";
import Footer from "../components/footer";
import TextField from "../components/text-field";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Residences = () => {
  return (
    <>
      <Seo page={SeoData.residences}/>
      <PageLabel name={"Chapter Two"} description={"The RESIDENCES"} />
      <IntroSection page={"theResidencesPage"} />
      <FullWidthImage img={"ResidencesImg1"} withPadding={false} />
      <TextField page={"Residences1"} />
      <FullWidthImage img={"ResidencesImg2"} withPadding={true} />
      <FullWidthImage img={"ResidencesImg3"} withPadding={false} />
      <FullWidthImage img={"ResidencesImg5"} withPadding={true} />
      <FullWidthImage img={"ResidencesImg4"} withPadding={false} />

      <ImageText
        page={"Residences1"}
        reversed={false}
        imgWidth={"59.5%"}
        textWidth={"38.9%"}
      />
      <ImageText
        page={"Residences2"}
        reversed={true}
        imgWidth={"59.5%"}
        textWidth={"38.9%"}
      />
      <NextPageSection page={"theResidencesPage"} />
      <Footer />
    </>
  );
};

export default Residences;
