import styled from "styled-components";
import { mediaMax } from "../helpers/MediaQueries";

export const ArticleNav = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    margin: 4px 44px;
    padding: 0;
    align-items: flex-start;
  `};
  ${mediaMax.phoneXL`
    margin: 24px 40px;
    min-width: 50%;
    flex-wrap: wrap;
  `};
  a {
    display: inline-block;
    min-width: 190px;
    color: #1a1818;
    cursor: pointer;
  }
  p {
    ${mediaMax.phoneXL`
      padding-top: 40px;
      width: 100%;
      order: 3;
      text-align: center;
      font-size: 11px;
    `};
  }
  .back:before {
    position: absolute;
    left: 0;
    content: "<";
    margin-top: 1px;
    font-size: 16px;
    ${mediaMax.tabletLandscape`
      left: -24px
    `};
    ${mediaMax.phoneXL`
      left: -16px;
      margin-top: 0;
      font-size: 14px;
    `};
  }
  .next,
  .back {
    ${mediaMax.tabletLandscape`
      max-width: 51px;
      min-width: 50px;
    `};
    ${mediaMax.phoneXL`
      min-width: 50%;
      font-size: 11px;
    `};
  }
  .next {
    text-align: right;
    ${mediaMax.tabletLandscape`
      text-align: left;
    `};
    ${mediaMax.phoneXL`
      text-align: right;
    `};
  }
  .next:after {
    position: absolute;
    right: 0;
    content: ">";
    margin-top: 1px;
    font-size: 16px;
    ${mediaMax.tabletLandscape`
      top: 0;
      right: -24px;
    `};
    ${mediaMax.phoneXL`
      margin-top: 0;
      right: -16px;
      font-size: 14px;
    `};
  }
  .source {
    text-decoration: underline;
  }
  &.bottomNav {
    margin: 70px 0;
    ${mediaMax.tabletLandscape`
      text-align: center;
      justify-content: center;
      margin: -8px 0 80px;
    `};
  }
`;

export const ArticleWrapper = styled.div`
  position: relative;
  padding: 150px 28px 0;
  ${mediaMax.tabletLandscape`
    padding: 130px 0 0;
  `};
`;

export const Type = styled.div`
  position: absolute;
  top: -95px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ArticleContent = styled.div`
  max-width: 828px;
  padding: 10px 0;
  margin: 0 auto;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 80px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 40px;
  `};
  h1 {
    padding: 0 5% 40px;
    text-align: center;
    ${mediaMax.tabletLandscape`
      padding: 0 5% 24px;
      font: normal normal normal 24px/30px RR CUSTOM;
    `};
  }
  h3 {
    font: normal normal 700 16px/22px Founders Grotesk;
    letter-spacing: 0.16px;
    color: #000000;
    padding-bottom: 24px;
    ${mediaMax.phoneXL`
      font: normal normal 500 15px/22px Founders Grotesk;
    `};
  }
  p {
    font: normal normal normal 16px/22px Founders Grotesk;
    letter-spacing: 0.16px;
    color: #000000;
    white-space: pre-line;
    ${mediaMax.phoneXL`
      font: normal normal 300 15px/22px Founders Grotesk;
    `};
  }
`;

export const Logo = styled.div`
  margin: 0 0 24px;
  width: 100%;
  h2 {
    text-align: center;
    font: normal normal normal 18px/22px RR CUSTOM;
    letter-spacing: 1.2px;
    color: #1A1818;
    text-transform: uppercase;
  }
  ${mediaMax.tabletLandscape`
    h2 {
      text-align: center;
    }
  `};
`;

export const MainImage = styled.div`
  max-width: 345px;
  width: 100%;
  margin: 0 auto 48px;
  ${mediaMax.tabletLandscape`
    max-width: 162px;
    margin: 0 auto 24px;
  `};
`;
