import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { INeighborhoodImageText } from "../../../interfaces/neighborhoodImageText-props";
import { ItemImageTextWrapper, ColLeft, ColRight } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ItemImageText: FC<INeighborhoodImageText> = ({
  name,
  reversed,
  imgWidth,
  textWidth,
  smallTitle,
}) => {
  const itemImageTextWrapperRef = useRef<HTMLDivElement>(null);
  const colLeftRef = useRef<HTMLDivElement>(null);
  const colRightRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let item;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: itemImageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          colLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          colRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (name) {
    case "contemporists":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheContemporists;
      break;
    case "architecture":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheArchitecture;
      break;
    case "artifacts":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheArtifacts;
      break;
    case "twilight":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheBowBridge;
      break;
    case "zoo":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheCentralParkZoo;
      break;
    case "winter":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheWinterWonderland;
      break;
    case "omakase":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheOmakase;
      break;
    case "trattorias":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheTrattorias;
      break;
    case "coffee":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheCoffeeBreak;
      break;
    case "brunch":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheBrunch;
      break;
    case "burger":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheBurger;
      break;
    case "bouquets":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheBouquets;
      break;
    case "bakeries":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheBakeries;
      break;
    case "roasts":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheRoasts;
      break;
    case "shoes":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheShoes;
      break;
    case "framed":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheFramed;
      break;
    case "bag":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheBagLove;
      break;
    case "oneStop":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheOneStopShop;
      break;
    case "polish":
      item = data.strapiTheNeighborhoodPage.LifestyleSection.ThePolish;
      break;
  }

  return (
    <ItemImageTextWrapper reversed={reversed} ref={itemImageTextWrapperRef}>
      <ColLeft textWidth={textWidth} ref={colLeftRef}>
        <h2 className={smallTitle ? "item-title-s" : "item-title"}>
          {item.Title}
        </h2>
        <p className={"article-copy-16 mb-24"}>{item.Description}</p>
        {item.Name && <h3 className={"item-name"}>{item.Name}</h3>}
        <p className={"item-address"}>{item.Address}</p>
        {item.InfoTitle && (
          <h3 className={"item-info-title"}>{item.InfoTitle}</h3>
        )}
        {item.InfoDescription && (
          <p className={"item-info-description"}>{item.InfoDescription}</p>
        )}
        {item.InfoTitle3 && (
          <h3 className={"item-info-title"}>{item.InfoTitle3}</h3>
        )}
        {item.InfoDescription3 && (
          <p className={"article-copy-16 mb-24"}>{item.InfoDescription3}</p>
        )}
        {item.InfoTitle2 && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle2}</h3>
            <p className={"item-info-description mb-40"}>
              {item.InfoDescription2}
            </p>
          </>
        )}
      </ColLeft>
      <ColRight imgWidth={imgWidth} ref={colRightRef}>
        <GatsbyImage
          image={item.HalfWidthImage1.localFile.childImageSharp.gatsbyImageData}
          alt={"200East83rd"}
        />
      </ColRight>
    </ItemImageTextWrapper>
  );
};

export default ItemImageText;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        TheContemporists {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheArchitecture {
          Address
          Description
          InfoDescription3
          InfoDescription2
          InfoTitle3
          InfoTitle2
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheArtifacts {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        TheBowBridge {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheCentralParkZoo {
          Address
          Description
          InfoDescription3
          InfoTitle3
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheWinterWonderland {
          Name
          Address
          Description
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        TheOmakase {
          Address
          Description
          InfoDescription3
          InfoDescription2
          InfoTitle3
          InfoTitle2
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheTrattorias {
          Title
          Name
          InfoTitle
          InfoDescription
          Description
          Address
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheCoffeeBreak {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBrunch {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBurger {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      MarketsSection {
        TheBouquets {
          Address
          Description
          InfoDescription
          InfoTitle
          Title
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBakeries {
          Address
          Description
          InfoDescription
          InfoTitle
          Title
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheRoasts {
          Address
          Description
          InfoDescription
          InfoTitle
          Title
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ShoppingSection {
        TheShoes {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheFramed {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBagLove {
          Address
          Description
          Title
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheOneStopShop {
          Address
          Description
          InfoDescription3
          InfoDescription2
          InfoTitle3
          InfoTitle2
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      LifestyleSection {
        ThePolish {
          Address
          Description
          InfoDescription
          InfoTitle
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
