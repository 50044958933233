import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { INeighborhoodImage } from "../../../interfaces/neighborhoodImage-props";
import { FullWidthImageWrapper, Image } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const FullWidthImage: FC<INeighborhoodImage> = ({ img }) => {
  const fullscreenImageWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let imgSrc;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: fullscreenImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          fullscreenImageWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (img) {
    case "Culture1":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Culture2":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .FullWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Culture3":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.FullWidthImage1
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Culture4":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.TheArtifacts
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Culture5":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.FullWidthImage2
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Culture6":
      imgSrc =
        data.strapiTheNeighborhoodPage.CultureSection.TheAuction.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks1":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks2":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.TheBowBridge.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks3":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.FullWidthImage1
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks4":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.TheWinterWonderland
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks5":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.TheWinterWonderland
          .FullWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Parks6":
      imgSrc =
        data.strapiTheNeighborhoodPage.ParkSection.FullWidthImage2
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Dining1":
      imgSrc =
        data.strapiTheNeighborhoodPage.DiningSection.TheBistros.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Dining2":
      imgSrc =
        data.strapiTheNeighborhoodPage.DiningSection.FullWidthImage1
          .FullWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Markets1":
      imgSrc =
        data.strapiTheNeighborhoodPage.MarketsSection.TheYourGrocer
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Markets2":
      imgSrc =
        data.strapiTheNeighborhoodPage.MarketsSection.TheIceCream.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Shopping1":
      imgSrc =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheShoes.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Shopping2":
      imgSrc =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheSweetSmells
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Shopping3":
      imgSrc =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheBangle.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "Shopping4":
      imgSrc =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheOneStopShop
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Lifestyle1":
      imgSrc =
        data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "Lifestyle2":
      imgSrc =
        data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout
          .FullWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
  }

  return (
    <FullWidthImageWrapper ref={fullscreenImageWrapperRef}>
      <Image>
        <GatsbyImage image={imgSrc} alt={"200East83rd"} />
      </Image>
    </FullWidthImageWrapper>
  );
};

export default FullWidthImage;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        MetropolitanMuseum {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          FullWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        FullWidthImage1 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        FullWidthImage2 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheArtifacts {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheAuction {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        TheConservatoryGarden {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBowBridge {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        FullWidthImage1 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheWinterWonderland {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          FullWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        FullWidthImage2 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        TheBistros {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        FullWidthImage1 {
          FullWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      MarketsSection {
        TheYourGrocer {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheIceCream {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ShoppingSection {
        TheShoes {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheSweetSmells {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBangle {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheOneStopShop {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      LifestyleSection {
        TheWorkout {
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheWorkout {
          FullWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
