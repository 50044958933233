import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  TeamItemsWrapper,
  TeamItem,
  Title,
  SubTitle,
  LinkWrapper,
  Link,
} from "./index.styled";

const TeamItems = () => {
  const data = useStaticQuery(query);
  const { TeamItem: items } = data.strapiTheTeamPage;

  return (
    <TeamItemsWrapper>
      {items.map((el: any) => (
        <TeamItem key={el.id}>
          {el.SubTitle && <SubTitle>{el.SubTitle}</SubTitle>}
          <Title href={`https://${el.Link}`}
                 target={"_blank"}
                 className={el.Description}
          >
            {el.Description}
          </Title>
        </TeamItem>
      ))}
    </TeamItemsWrapper>
  );
};

export default TeamItems;

const query = graphql`
  {
    strapiTheTeamPage {
      TeamItem {
        Description
        SubTitle
        Link
        id
      }
    }
  }
`;
