import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ResponsiveItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 48px;
  ${mediaMax.tabletLandscape`
    flex-direction: column;
    padding: 0 15.2% 48px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 40px;
  `};
`;

export const Image = styled("div")`
  width: 100%;
  padding-bottom: 24px;
`;

export const Content = styled("div")<{ border: boolean }>`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: ${(props: { border: boolean }) =>
    props.border ? "1px solid black" : "none"};
  .item-name {
    padding-bottom: 6px;
    ${mediaMax.tabletLandscape`
      font-size: 18px;
      line-height: 20px;
    `};
  }
  .item-address {
    ${mediaMax.tabletLandscape`
      font-size: 12px;
      line-height: 14px;
    `};
    ${mediaMax.phoneXL`
      font-size: 11px;
      line-height: 14px;
    `};
  }
  .fontL {
    ${mediaMax.tabletLandscape`
      font-size: 16px;
      line-height: 18px;
    `};
    ${mediaMax.phoneXL`
      font-size: 14px;
      line: height: 18px;
    `};
  }
  .fontS {
    ${mediaMax.phoneXL`
      font-size: 10px;
      line: height: 12px;
    `};
  }
`;
