import styled, { createGlobalStyle } from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

import foundersGroteskNormalOtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Regular.otf";
import foundersGroteskNormalTtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Regular.ttf";
import foundersGroteskNormalWoff from "../../fonts/Founders-Grotesk/FoundersGrotesk-Regular.woff";
import foundersGroteskLightOtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Light.otf";
import foundersGroteskLightTtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Light.ttf";
import foundersGroteskLightWoff from "../../fonts/Founders-Grotesk/FoundersGrotesk-Light.woff";
import foundersGroteskBoldOtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Bold.otf";
import foundersGroteskBoldTtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Bold.ttf";
import foundersGroteskBoldWoff from "../../fonts/Founders-Grotesk/FoundersGrotesk-Bold.woff";
import foundersGroteskSemiBoldOtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Semibold.otf";
import foundersGroteskSemiBoldTtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Semibold.ttf";
import foundersGroteskSemiBoldWoff from "../../fonts/Founders-Grotesk/FoundersGrotesk-Semibold.woff";
import foundersGroteskMediumOtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Medium.otf";
import foundersGroteskMediumTtf from "../../fonts/Founders-Grotesk/FoundersGrotesk-Medium.ttf";
import foundersGroteskMediumWoff from "../../fonts/Founders-Grotesk/FoundersGrotesk-Medium.woff";
import RRCustomRegOtf from "../../fonts/RR-custom/RR_Custom-TC.otf";
import RRCustomRegTtf from "../../fonts/RR-custom/RR_Custom-TC.ttf";
import RRCustomRegWoff from "../../fonts/RR-custom/RR_Custom-TC.woff";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Founders Grotesk';
    src: url(${foundersGroteskNormalTtf}) format('ttf'),
    url(${foundersGroteskNormalOtf}) format('otf'),
    url(${foundersGroteskNormalWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Founders Grotesk';
    src: url(${foundersGroteskLightTtf}) format('ttf'),
    url(${foundersGroteskLightOtf}) format('otf'),
    url(${foundersGroteskLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Founders Grotesk';
    src: url(${foundersGroteskMediumTtf}) format('ttf'),
    url(${foundersGroteskMediumOtf}) format('otf'),
    url(${foundersGroteskMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Founders Grotesk';
    src: url(${foundersGroteskSemiBoldTtf}) format('ttf'),
    url(${foundersGroteskSemiBoldOtf}) format('otf'),
    url(${foundersGroteskSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Founders Grotesk';
    src: url(${foundersGroteskBoldTtf}) format('ttf'),
    url(${foundersGroteskBoldOtf}) format('otf'),
    url(${foundersGroteskBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'RR CUSTOM';
    src: url(${RRCustomRegTtf}) format('ttf'),
    url(${RRCustomRegOtf}) format('otf'),
    url(${RRCustomRegWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  html {
    font-family: RR CUSTOM, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-weight: 400;
    color: #1A1818;
  }

  body {
    margin: 0;
    background: #FBF7F2;
    overflow-y: scroll;
  }

  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  


article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
}


img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

h1 {
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

h3 {
  padding: 0;
  margin: 0;
}

h4 {
  padding: 0;
  margin: 0;
}

h5 {
  padding: 0;
  margin: 0;
}

h6 {
  padding: 0;
  margin: 0;
}

hgroup {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

ol {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

dl {
  padding: 0;
  margin: 0;
}

dd {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

figure {
  padding: 0;
  margin: 0;
}

pre {
  margin: 0;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
}

fieldset {
  padding: 0;
  margin: 0;
}

blockquote {
  padding: 0;
  margin: 0;
}

form {
  padding: 0;
  margin: 0;
}

noscript {
  padding: 0;
  margin: 0;
}

iframe {
  padding: 0;
  margin: 0;
}

hr {
  padding: 0;
  margin: 0 0 calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

li {
  margin-bottom: calc(1.45rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}

.body-title {
  font: normal normal normal 50px/55px RR CUSTOM;
  letter-spacing: 4px;
  text-transform: uppercase;
  opacity: 0.88;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 48px/52px RR CUSTOM;
    letter-spacing: 2.1px;
  `};
  ${mediaMax.phoneXL`
    font: normal normal normal 35px/38px RR CUSTOM;
    letter-spacing: 2.1px;
  `};
}

.body-copy {
  font: normal normal normal 24px/34px RR CUSTOM;
  letter-spacing: 0;
  color: #000000;
  ${mediaMax.phoneXL`
    font: normal normal normal 19px/24px RR CUSTOM;
  `};
}  

.article-copy-16 {
  font: normal normal 300 16px/20px Founders Grotesk;
  letter-spacing: 0.16px;
  color: #000000;
  ${mediaMax.tabletLandscape`
    font: normal normal 300 16xp/22px Founders Grotesk;
  `}
}
.item-name {
  padding-bottom: 8px;
  text-align: left;
  font: normal normal 600 24px/24px Founders Grotesk;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal 600 18px/18px Founders Grotesk;
  `};
}
.item-title {
  padding-bottom: 24px;
  text-align: left;
  font: normal normal normal 50px/55px RR CUSTOM;
  letter-spacing: 4px;
  color: #1A1818;
  text-transform: uppercase;
  opacity: 1;
}
.item-title-s {
  padding-bottom: 24px;
  text-align: left;
  font: normal normal normal 30px/40px RR CUSTOM;
  letter-spacing: 1.8px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.item-info-title {
  padding-bottom: 8px;
  text-align: left;
  font: normal normal 600 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal 600 12px/20px Founders Grotesk;
  `};
}
.item-info-description {
  padding-bottom: 8px;
  text-align: left;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #000000;
  text-transform: uppercase;
  white-space: pre-line;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 12px/20px Founders Grotesk;
    padding-bottom: 0;
  `};
}
.item-address {
  padding-bottom: 24px;
  text-align: left;
  font: normal normal normal 12px/20px Founders Grotesk;
  letter-spacing: 0.96px;
  color: #1A1818;
  text-transform: uppercase;
  opacity: 1;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 12px/20px Founders Grotesk;
  `};
}

.quotation-text-xl {
  text-align: center;
  font: normal normal normal 50px/55px RR CUSTOM;
  letter-spacing: 4px;
  color: #1A1818;
  text-transform: uppercase;
  opacity: 1;
}
.quotation-author-18 {
  font: normal normal 700 20px/20px Founders Grotesk;
  color: #1A1818;
  text-transform: uppercase;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 20px/20px Founders Grotesk;
  `};
}
.button-text {
  font: normal normal normal 14px/14px Founders Grotesk;
  font-weight: 400;
  letter-spacing: 1.12px;
  color: #000000;
  text-transform: uppercase;
  ${mediaMax.tabletLandscape`
    font: normal normal normal 14px/14px Founders Grotesk;
  `};
}
.mb-24 {
  margin-bottom: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-40 {
  margin-bottom: 40px;
  ${mediaMax.tabletLandscape`
    margin-bottom: 20px;
  `};
}
.mb-48 {
  margin-bottom: 48px;
}
.page-enter-active {
  animation: fade-in 2000ms forwards;
}
.page-exit-active {
  animation: fade-out 1s forwards;
}

@keyframes fade-in {
  0%{
    opacity: 0;
    transform: translateY(400px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    top: 0;
  }
}
@keyframes fade-out {
  0%{
    opacity: 1;
    transform: translateY(0);
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  100% {
    transform: translateY(-400px);
    opacity: 0;
  }
}
`;

export const LayoutWrapper = styled.div``;

export const PageWrapper = styled.div`
  transition: 0.8s ease-out;
  &.loading {
    opacity: 0;
  }
`;
