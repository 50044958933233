import React, { FC, useState } from "react";
import {
  ContactFormContainer,
  ContactText,
  ContactThanks,
} from "./index.styled";
import ContactForm from "./contact-form";

const ContactBody: FC = () => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const showSuccess = () => {
    setSubmitted(true);
  };

  return (
    <ContactFormContainer>
      <div>
        {submitted ? (
          <ContactThanks className={"thanks-text"}>
            Thank you for registering. <br /> Our sales team will be in touch.
          </ContactThanks>
        ) : (
          <>
            <ContactText>REGISTER FOR MORE INFORMATION</ContactText>
            <ContactForm showSuccess={showSuccess} />
          </>
        )}
      </div>
    </ContactFormContainer>
  );
};

export default ContactBody;
