import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { INeighborhoodResponsiveItem } from "../../../interfaces/neighborhoodResponsiveItem-props";

import { ResponsiveItemWrapper, Image, Content } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ResponsiveItem: FC<INeighborhoodResponsiveItem> = ({
  name,
  border,
  id,
}) => {
  const itemImageTextWrapperRef = useRef<HTMLDivElement>(null);
  const colLeftRef = useRef<HTMLDivElement>(null);
  const colRightRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let item;
  let image;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: itemImageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          colRightRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          colLeftRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (name) {
    case "metropolitan":
      item = data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum;
      image =
        data.strapiTheNeighborhoodPage.CultureSection.MetropolitanMuseum
          .HalfWidthImage2.localFile.childImageSharp.gatsbyImageData;
      break;
    case "guggenheim":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheArchitecture;
      image =
        data.strapiTheNeighborhoodPage.CultureSection.TheArchitecture
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "jewish":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheArtifacts;
      image =
        data.strapiTheNeighborhoodPage.CultureSection.TheArtifacts
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "books":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheBooks;
      image =
        data.strapiTheNeighborhoodPage.CultureSection.TheBooks
          .HalfWidthImageLeft.localFile.childImageSharp.gatsbyImageData;
      break;
    case "auction":
      item = data.strapiTheNeighborhoodPage.CultureSection.TheAuction;
      image =
        data.strapiTheNeighborhoodPage.CultureSection.TheAuction.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "garden":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden;
      image =
        data.strapiTheNeighborhoodPage.ParkSection.TheConservatoryGarden
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "bridge":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheBowBridge;
      image =
        data.strapiTheNeighborhoodPage.ParkSection.TheBowBridge.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "zoo":
      item = data.strapiTheNeighborhoodPage.ParkSection.TheCentralParkZoo;
      image =
        data.strapiTheNeighborhoodPage.ParkSection.TheCentralParkZoo
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "sushi":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheOmakase;
      image =
        data.strapiTheNeighborhoodPage.DiningSection.TheOmakase.HalfWidthImage1
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "brunch":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheBrunch;
      image =
        data.strapiTheNeighborhoodPage.DiningSection.TheBrunch.HalfWidthImage1
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "bluestone":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheCoffeeBreak;
      image =
        data.strapiTheNeighborhoodPage.DiningSection.TheCoffeeBreak
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "quatorze":
      item = data.strapiTheNeighborhoodPage.DiningSection.TheBistros;
      image =
        data.strapiTheNeighborhoodPage.DiningSection.TheBistros.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "elis":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheYourGrocer;
      image =
        data.strapiTheNeighborhoodPage.MarketsSection.TheYourGrocer
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "lobels":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheRoasts;
      image =
        data.strapiTheNeighborhoodPage.MarketsSection.TheRoasts.HalfWidthImage1
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "iceCream":
      item = data.strapiTheNeighborhoodPage.MarketsSection.TheIceCream;
      image =
        data.strapiTheNeighborhoodPage.MarketsSection.TheIceCream.FullWidthImage
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "ralph":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheOneStopShop;
      image =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheOneStopShop
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "acne":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheShoes;
      image =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheShoes.HalfWidthImage1
          .localFile.childImageSharp.gatsbyImageData;
      break;
    case "fueguia":
      item = data.strapiTheNeighborhoodPage.ShoppingSection.TheSweetSmells;
      image =
        data.strapiTheNeighborhoodPage.ShoppingSection.TheSweetSmells
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
    case "polish":
      item = data.strapiTheNeighborhoodPage.LifestyleSection.ThePolish;
      image =
        data.strapiTheNeighborhoodPage.LifestyleSection.ThePolish
          .HalfWidthImage1.localFile.childImageSharp.gatsbyImageData;
      break;
    case "workout":
      item = data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout;
      image =
        data.strapiTheNeighborhoodPage.LifestyleSection.TheWorkout
          .FullWidthImage.localFile.childImageSharp.gatsbyImageData;
      break;
  }

  return (
    <ResponsiveItemWrapper ref={itemImageTextWrapperRef} id={id}>
      <Image ref={colRightRef}>
        <GatsbyImage image={image} alt={"200East83rd"} />
      </Image>

      <Content ref={colLeftRef} border={border}>
        <h3 className={"item-name"}>{item.Name || item.Title}</h3>
        <p className={"item-address"}>{item.Address}</p>
        <p className={"article-copy-16 fontL mb-24"}>{item.Description}</p>
        {item.InfoTitle && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle}</h3>
            <p className={"item-info-description mb-24"}>
              {item.InfoDescription}
            </p>
          </>
        )}
        {item.InfoTitle3 && (
          <>
            <h3 className={"item-info-title"}>{item.InfoTitle3}</h3>
            <p className={"item-info-description mb-24"}>
              {item.InfoDescription3}
            </p>
          </>
        )}
        {item.MobileInfoTitle && (
          <>
            <h3 className={"item-info-title"}>{item.MobileInfoTitle}</h3>
            <p className={"item-info-description fontS"}>
              {item.MobileInfoDescription}
            </p>
          </>
        )}
      </Content>
    </ResponsiveItemWrapper>
  );
};

export default ResponsiveItem;

const query = graphql`
  {
    strapiTheNeighborhoodPage {
      CultureSection {
        MetropolitanMuseum {
          Name
          Address
          Title
          Description
          InfoTitle
          InfoDescription
          HalfWidthImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheArchitecture {
          Address
          Description
          InfoDescription3
          InfoTitle3
          Name
          Title
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheArtifacts {
          Name
          Address
          Description
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBooks {
          Address
          Description
          Name
          Title
          InfoTitle
          InfoDescription
          HalfWidthImageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheAuction {
          Address
          Description
          Name
          Title
          InfoTitle
          InfoDescription
          MobileInfoDescription
          MobileInfoTitle
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ParkSection {
        TheConservatoryGarden {
          Address
          Description
          InfoDescription
          Name
          InfoTitle
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBowBridge {
          Address
          Description
          Name
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheCentralParkZoo {
          Title
          Address
          Description
          MobileInfoDescription
          MobileInfoTitle
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      DiningSection {
        TheOmakase {
          Name
          Address
          Title
          Description
          InfoTitle3
          InfoDescription3
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBrunch {
          Name
          Address
          Title
          Description
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheCoffeeBreak {
          Name
          Address
          Description
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheBistros {
          Name
          Address
          Description
          MobileInfoTitle
          MobileInfoDescription
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      MarketsSection {
        TheYourGrocer {
          Address
          Description
          Name
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheRoasts {
          Address
          Description
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheIceCream {
          Address
          Description
          Name
          MobileInfoTitle
          MobileInfoDescription
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      ShoppingSection {
        TheOneStopShop {
          Address
          Description
          Name
          InfoTitle3
          InfoDescription3
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheShoes {
          Address
          Description
          Name
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheSweetSmells {
          Address
          Description
          Name
          InfoTitle
          InfoDescription
          MobileInfoTitle
          MobileInfoDescription
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      LifestyleSection {
        ThePolish {
          Address
          Description
          Name
          InfoTitle
          InfoDescription
          HalfWidthImage1 {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        TheWorkout {
          Address
          Description
          Name
          InfoTitle
          InfoDescription
          MobileInfoTitle
          MobileInfoDescription
          FullWidthImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
