import React, { FC, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { RamsaSectionWrapper, RamsaImage, RamsaText } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const RamsaSection: FC = () => {
  const ramsaSectionWrapperRef = useRef<HTMLDivElement>(null);
  const ramsaImageRef = useRef<HTMLDivElement>(null);
  const RamsaTextRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  const { ImageAndText: items } = data.strapiTheBuildingPage;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ramsaSectionWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          RamsaTextRef?.current || null,
          0.5,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          ramsaImageRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  return (
    <RamsaSectionWrapper ref={ramsaSectionWrapperRef}>
      <RamsaImage ref={ramsaImageRef}>
        <GatsbyImage
          image={items.Image[0].localFile.childImageSharp.gatsbyImageData}
          alt={"200East83rd"}
        />
      </RamsaImage>
      <RamsaText className={"body-copy"} ref={RamsaTextRef}>
        {items.Text}
      </RamsaText>
    </RamsaSectionWrapper>
  );
};

export default RamsaSection;

const query = graphql`
  {
    strapiTheBuildingPage {
      id
      ImageAndText {
        Text
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
