import styled from "styled-components";
import { mediaMax } from "../../../../helpers/MediaQueries";

export const ContactFormWrapper = styled.form`
  max-width: 488px;
  margin: 0 auto 54px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 24px;
  ${mediaMax.tabletLandscape`
    max-width: 584px;
    margin: 0 auto;
  `}
  .w-70 {
    width: calc(100% / 3 * 2 - 12px);
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }

  .w-50 {
    width: calc(50% - 12px);
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }

  .w-30 {
    width: calc(100% / 3 - 16px);
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }

  ${mediaMax.phoneXL`
    .wm-50 {
      width: calc(50% - 12px);
    }
  `}
`;
export const FormButton = styled.button`
  background: #1a1818;
  border: 1px solid #1a1818;
  text-align: center;
  font: normal normal 600 14px/40px Founders Grotesk;
  letter-spacing: 1.12px;
  color: #ffffff;
  width: 106px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
  padding-top: 2px;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `}
`;
