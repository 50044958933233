import React, { FC, useRef, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { FullWidthImageWrapper, Image } from "./index.styled";
import { IFullWidthImage } from "../../interfaces/fullWidthImage-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const FullWidthImage: FC<IFullWidthImage> = ({ ...props }) => {
  const fullscreenImageWrapperRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(query);
  let imgSrc;

  useEffect(() => {
    ScrollTrigger.create({
      trigger: fullscreenImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=18% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          fullscreenImageWrapperRef?.current || null,
          0.8,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        ),
    });
  }, []);

  switch (props.img) {
    case "BuildingImg1":
      imgSrc =
        data.strapiTheBuildingPage.FullWidthImage1.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "BuildingImg2":
      imgSrc =
        data.strapiTheBuildingPage.FullWidthImage2.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "BuildingImg3":
      imgSrc =
        data.strapiTheBuildingPage.FullWidthImage3.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "BuildingImg4":
      imgSrc =
        data.strapiTheBuildingPage.FullWidthImage4.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "ResidencesImg1":
      imgSrc =
        data.strapiTheResidencesPage.FullWidthImage1.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "ResidencesImg2":
      imgSrc =
        data.strapiTheResidencesPage.FullWidthImage2.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "ResidencesImg3":
      imgSrc =
        data.strapiTheResidencesPage.FullWidthImage3.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "ResidencesImg4":
      imgSrc =
        data.strapiTheResidencesPage.FullWidthImage4.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "ResidencesImg5":
      imgSrc =
        data.strapiTheResidencesPage.FullWidthImage5.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "PenthousesImg1":
      imgSrc =
        data.strapiThePenthousesPage.FullWidthImage1.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "PenthousesImg2":
      imgSrc =
        data.strapiThePenthousesPage.FullWidthImage2.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "PenthousesImg3":
      imgSrc =
        data.strapiThePenthousesPage.FullWidthImage3.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "PenthousesImg4":
      imgSrc =
        data.strapiThePenthousesPage.FullWidthImage4.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "PenthousesImg5":
      imgSrc =
        data.strapiThePenthousesPage.FullWidthImage5.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg1":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage1.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg2":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage2.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg3":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage3.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg4":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage4.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg5":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage5.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg6":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage6.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg7":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage7.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg8":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage8.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg9":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage9.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "AmenitiesImg10":
      imgSrc =
        data.strapiTheAmenitiesPage.FullWidthImage10.FullWidthImage.localFile
          .childImageSharp.gatsbyImageData;
      break;
    case "NeighborhoodImg1":
      imgSrc =
        data.strapiTheNeighborhoodPage.Map.MapImage.localFile.childImageSharp
          .gatsbyImageData;
      break;
  }

  return (
    <FullWidthImageWrapper
      withPadding={props.withPadding}
      ref={fullscreenImageWrapperRef}
    >
      <Image>
        <GatsbyImage image={imgSrc} alt={"200East83rd"} />
      </Image>
    </FullWidthImageWrapper>
  );
};

export default FullWidthImage;

const query = graphql`
  {
    strapiTheBuildingPage {
      FullWidthImage1 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage2 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage3 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage4 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheResidencesPage {
      FullWidthImage1 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage2 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage3 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage4 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage5 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiThePenthousesPage {
      FullWidthImage1 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage2 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage3 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage4 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage5 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheAmenitiesPage {
      FullWidthImage1 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage2 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage3 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage4 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage5 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage6 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage7 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage8 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage9 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      FullWidthImage10 {
        FullWidthImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiTheNeighborhoodPage {
      Map {
        MapImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
