import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const TextFieldWrapper = styled("div")`
  padding: 0 10.3% 100px;
  display: flex;
  justify-content: flex-start;
  ${mediaMax.tabletLandscape`
    padding: 0 15.2% 56px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 7% 48px;
  `};
`;

export const Text = styled.div`
  width: 49%;
  white-space: pre-line;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `};
  ${mediaMax.phoneXL`

  `};
`;
