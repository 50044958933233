import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const LegalItemsWrapper = styled.div`
  min-height: calc(100vh - 265px);
  padding: 0 10px 72px;
  max-width: 828px;
  margin: 0 auto;
  text-align: left;
  color: #000000;
  letter-spacing: 0.16px;
  ${mediaMax.tabletLandscape`
    padding: 0 13% 72px;
  `};
  ${mediaMax.phoneXL`
    padding: 0 25px 72px;
  `};
`;

export const Title = styled.div`
  padding-bottom: 24px;
  font: normal normal 700 16px/22px Founders Grotesk;
`;

export const Description = styled.div`
  padding-bottom: 24px;
  font: normal normal 400 16px/22px Founders Grotesk;
`;

export const SubTitle = styled.div`
  padding-bottom: 24px;
  font: normal normal 700 16px/22px Founders Grotesk;
`;

export const SubDescription = styled.div`
  padding-bottom: 24px;
  font: normal normal 400 16px/22px Founders Grotesk;
  white-space: pre-line;
`;
