import React, { FC } from "react";
import { ShoppingWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import Item1Text from "../item1-text";
import ItemImageText from "../item-image-text";
import ItemImageImageText from "../item-image-imageText";

const Shopping: FC = () => {
  return (
    <ShoppingWrapper>
      <Item>
        <ItemImageText
          name={"shoes"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
        <FullWidthImage img={"Shopping1"} />
      </Item>
      <Item>
        <ItemImageText
          name={"framed"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
          smallTitle={true}
        />
      </Item>
      <Item>
        <ItemImageImageText name={"frock"} reversed={true} />
      </Item>
      <Item>
        <ItemImageText
          name={"bag"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>
      <Item>
        <Item1Text name={"Kids"} smallTitle={true} />
      </Item>
      <Item>
        <FullWidthImage img={"Shopping2"} />
        <Item1Text name={"Smells"} />
      </Item>
      <Item>
        <FullWidthImage img={"Shopping3"} />
        <Item1Text name={"Bangle"} />
      </Item>
      <Item>
        <Item1Text name={"Home"} smallTitle={true} />
      </Item>
      <FullWidthImage img={"Shopping4"} />
      <ItemImageText
        name={"oneStop"}
        reversed={false}
        imgWidth={""}
        textWidth={""}
      />
    </ShoppingWrapper>
  );
};

export default Shopping;
