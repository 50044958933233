import * as React from "react";
import IntroSection from "../components/intro-section";
import FullWidthImage from "../components/full-width-image";
import ImageText from "../components/image-text";
import TextField from "../components/text-field";
import HalfWidthImage from "../components/half-width-image";
import NextPageSection from "../components/next-page-section";
import Footer from "../components/footer";
import PageLabel from "../components/page-label";
import Seo from "../components/seo";
import SeoData from "../data/seo";

const Amenities = () => {
  return (
    <>
      <Seo page={SeoData.amenities}/>
      <PageLabel name={"Chapter FOUR"} description={"The AMENITIES"} />
      <IntroSection page={"theAmenitiesPage"} />
      <FullWidthImage img={"AmenitiesImg1"} withPadding={false} />
      <TextField page={"Amenities1"} />
      <FullWidthImage img={"AmenitiesImg2"} withPadding={true} />
      <ImageText
        page={"Amenities1"}
        reversed={true}
        imgWidth={"69.5%"}
        textWidth={"28%"}
      />
      <FullWidthImage img={"AmenitiesImg3"} withPadding={false} />
      <FullWidthImage img={"AmenitiesImg4"} withPadding={false} />
      <FullWidthImage img={"AmenitiesImg5"} withPadding={true} />
      <ImageText
        page={"Amenities2"}
        reversed={false}
        imgWidth={"69.5%"}
        textWidth={"28.9%"}
      />
      <HalfWidthImage
        img={"AmenitiesImg2"}
        width={"69.5%"}
        position={"flex-end"}
      />
      <FullWidthImage img={"AmenitiesImg6"} withPadding={false} />
      <FullWidthImage img={"AmenitiesImg7"} withPadding={true} />
      <FullWidthImage img={"AmenitiesImg8"} withPadding={false} />
      <FullWidthImage img={"AmenitiesImg9"} withPadding={true} />
      <FullWidthImage img={"AmenitiesImg10"} withPadding={false} />
      <NextPageSection page={"theAmenitiesPage"} />
      <Footer />
    </>
  );
};

export default Amenities;
