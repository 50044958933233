import React, { FC } from "react";
import { CultureWrapper, Item } from "./index.styled";

import FullWidthImage from "../full-width-image";
import ImageImage from "../image-image";
import Item1Text from "../item1-text";
import ItemImageText from "../item-image-text";
import TheMasters from "../the-masters";
import ItemImageImageText from "../item-image-imageText";

const Culture: FC = () => {
  return (
    <CultureWrapper>
      <Item>
        <FullWidthImage img={"Culture1"} />
        <ImageImage img={"Museum1-2"} />
        <Item1Text name={"Museum"} />
        <FullWidthImage img={"Culture2"} />
        <ImageImage img={"Museum3-4"} />
      </Item>

      <Item>
        <ItemImageText
          name={"contemporists"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>

      <Item>
        <TheMasters />
      </Item>

      <Item>
        <ItemImageText
          name={"architecture"}
          reversed={true}
          imgWidth={""}
          textWidth={""}
        />
      </Item>

      <Item>
        <FullWidthImage img={"Culture3"} />
      </Item>

      <Item>
        <FullWidthImage img={"Culture4"} />
        <ItemImageText
          name={"artifacts"}
          reversed={false}
          imgWidth={""}
          textWidth={""}
        />
      </Item>

      <Item>
        <FullWidthImage img={"Culture5"} />
      </Item>

      <Item>
        <Item1Text name={"Auction"} />
        <FullWidthImage img={"Culture6"} />
      </Item>

      <Item>
        <Item1Text name={"Memberships"} />
      </Item>

      <ItemImageImageText name={"books"} reversed={false} />
    </CultureWrapper>
  );
};

export default Culture;
